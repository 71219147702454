export const config = {
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: true,
  // focusOnChange: true,
  accessibility: true,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 968,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        dots: true
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        variableWidth: false,
        dots: true
      }
    }
  ]
};
