export const initDynamicLayers = function (event) {
  const sections = document.querySelectorAll('.dynamic-layers .dynamic-layers__content');
  const background = document.querySelectorAll('.dynamic-layers .dynamic-layers__static .layer-bg');

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: window.innerWidth > 968 ? 0.1 : 0
  };

  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        intersectionHandler(entry);
      } else if (!entry.isIntersecting && entry.boundingClientRect.top > 0) {
        document.querySelector('.dynamic-layers__indicators .dynamic-layers__indicator a[href="#' + entry.target.id + '"]').parentNode.classList.remove('active');
        entry.target.classList.remove('active');
      }
    });
  }, options);

  sections.forEach(section => { observer.observe(section); });

  function intersectionHandler (entry) {
    const id = entry.target.id;

    entry.target.classList.add('active');

    let target = document.querySelector('.dynamic-layers__indicators .dynamic-layers__indicator a[href="#' + id + '"]').parentNode;

    target.classList.add('active');

    while (target === target.previousElementSibling) {
      target.classList.add('active');
    }
  }

  let backgroundObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('active');
        // observer.unobserve(entry.target)
      } else if (!entry.isIntersecting && entry.target.children && entry.target.children[0] && entry.target.children[0].children && entry.target.children[0].children[0]) {
        // entry.target.classList.remove('active')
        entry.target.children[0].children[0].pause();
      }
    });
  }, { threshold: 0.85 });
  background.forEach(section => { backgroundObserver.observe(section); });

  const videoContainer = $('[data-dyn-layers-video-container]');

  if (videoContainer.length > 0) {
    videoContainer.each((index, element) => new DynamicLayerBackgroundVideo(element, {}));
  }
}

class DynamicLayerBackgroundVideo {
  constructor (element, config) {
    this.container = $(element);
    this.navButton = $('header .nav-button');
    this.video = this.container.find('[data-video]');
    this.videoControl = this.container.find('[data-video-control]');
    // !!! Modified from original
    this.captionControl = this.container.find('[data-caption-control]');
    this.playing = false;
    this.autoplay = window.matchMedia('(min-width: 968px)').matches;
    this.autoplay_set = (this.container.find('[play]').length > 0 ? 'true' : 'false');
    this.accessNav = $('.accessibility-navigation');
    this.globalButton = $('button');
    this.init();
  }

  init () {
    this.accessibility();

    if (this.videoControl) {
      this.video.on('playing', () => {
        this.playing = true;
      });

      this.videoControl.on('click', (e) => {
        this.onPressVideoControl(e);
      });
    }

    $(this.navButton).on('click', () => {
      this.pause();
    });

    $(this.accessNav).on('click', () => {
      this.pause();
    });

    $(this.globalButton).on('click', () => {
      if (!$(this).has('btn-video-control')) {
        this.pause();
      }
    });

    // !!! Modified from original
    $(this.captionControl).on('click', () => {
      this.captionToggle();
    });

    const video = this.video.get(0);
    video.addEventListener('pause', () => {
      this.pause();
    });
    let track = video.textTracks && video.textTracks[0];
    if (track) {
      track.mode = 'hidden';
    }
  }

  onPressVideoControl (e) {
    e.preventDefault();

    if (this.playing) {
      this.pause();
    } else {
      this.play();
    }
  }

  play () {
    const video = this.video.get(0);
    const videoControlIcon = this.videoControl.find('[data-video-icon]:first');
    const iconName = videoControlIcon.attr('data-icon-playing');
    const icon = videoControlIcon.find('svg').clone();
    const videoControlLabel = this.videoControl.find('[data-video-label]:first');
    const videoPos = this.container.offset().top;
    const container = this.container;
    const dynamicLayerContainer = document.getElementsByClassName('dynamic-layers__static')[0];
    const thisFunction = this;

    video.play();

    this.playing = true;
    this.container.addClass('playing');
    dynamicLayerContainer.classList.add('hasPlayed');

    icon.find('use').attr('xlink:href', iconName);
    videoControlIcon.empty().append(icon);
    videoControlLabel.html(videoControlLabel.attr('data-label-playing'));

    this.videoControl.blur();

    // stop playing on scrolling
    $(window).on('scroll', function () {
      // !!! Modified from original
      // var containerHeight = container.height()
      var containerHeight = container.closest('.dynamic-layers').height();

      if ($(this).scrollTop() >= (videoPos + containerHeight) - 100) {
        if (thisFunction.playing) {
          thisFunction.pause();
        }
      }
    });
    video.addEventListener('ended', (e) => {
      console.log('Video ended.');
      this.pause();
    });
  }

  pause () {
    const video = this.video.get(0);
    const videoControlIcon = this.videoControl.find('[data-video-icon]:first');
    const iconName = videoControlIcon.attr('data-icon-pausing');
    const icon = videoControlIcon.find('svg').clone();
    const videoControlLabel = this.videoControl.find('[data-video-label]:first');

    video.pause();
    this.playing = false;

    this.container.removeClass('playing');

    icon.find('use').attr('xlink:href', iconName);
    videoControlIcon.empty().append(icon);
    videoControlLabel.html(videoControlLabel.attr('data-label-pausing'));
  }

  // !!! Modified from original
  captionToggle () {
    const video = this.video.get(0);
    const videoControlIcon = this.captionControl.find('[data-caption-icon]:first');
    const icon = videoControlIcon.find('svg').clone();
    const videoControlLabel = this.captionControl.find('[data-caption-label]:first');

    let track = video.textTracks && video.textTracks[0];
    if (track) {
      if (track.mode === 'hidden') {
        track.mode = 'showing';
        icon.find('use').attr('xlink:href', videoControlIcon.attr('data-captions-off'));
        videoControlLabel.html(videoControlLabel.attr('data-captions-off'));
      } else {
        track.mode = 'hidden';
        icon.find('use').attr('xlink:href', videoControlIcon.attr('data-captions-on'));
        videoControlLabel.html(videoControlLabel.attr('data-captions-on'));
      }
    }

    videoControlIcon.empty().append(icon);
  }

  accessibility () {
    if (window.matchMedia('(prefers-reduced-motion)').matches) {
      this.video.removeAttr('autoplay');
      this.pause();
    } else if (!this.playing && this.autoplay && this.autoplay_set === 'true') {
      this.play();
    } else {
      this.video.attr('preload', 'none');
    }
  }
}
