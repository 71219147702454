class ComponentPrivacy {
  constructor(props) {
    this.node = props.node;
    this.privacyCategory = props.privacyCategory;
    this.init = props.init;
    this.userIsAuthenticated = document.querySelector('.cms-toolbar')
  }

  ready() {
    if(this.userIsAuthenticated || django_privacy_mgmt.getPreference(this.privacyCategory)) {
      this.init();
    } else {
      // Above works because privacy manager reloads the page after setting the preference.
      // Should be replaced with custom event
      window.django_privacy_mgmt.addListener('change', allowed => {
        if(allowed) {
          this.init();
        }
      }, this.privacyCategory);
    }
  }

  updatePreference(value, permanent=false) {
    django_privacy_mgmt.setPreference(this.privacyCategory, value);
    this.init();
  }
}

export default ComponentPrivacy;
