import $ from 'jquery';
import Cookies from 'js-cookie';

export const FontSettings = (selector) => {
  addListeners(selector);
};

export const addListeners = selector => {
  selector.each((index, node) => {
    node.addEventListener('click', switchMode);
  });
};

export const switchMode = e => {
  if (typeof e.target !== 'undefined' && typeof e.target.value !== 'undefined') {
    Cookies.set('lwl-font-settings', e.target.value, { expires: 7 });
    if (e.target.value === 'true') {
      $('html').addClass('font-size-1');
    } else {
      $('html').removeClass('font-size-1');
    }
  }
};

export default FontSettings;
