import Swiper, {Keyboard, Navigation, Pagination} from "swiper";
import ComponentPrivacy from "./utils/component-privacy";

export default class C042ShortsSlider {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.id = props.id;

    this.privacyManager = new ComponentPrivacy({
      node: this.node,
      privacyCategory: 'YOUTUBE_VIDEOS',
      init: this.init.bind(this),
    });
    this.checkbox = this.node.querySelector('[data-component-part="privacy-checkbox"]');
    node.querySelector('[data-component-part="accept"]').addEventListener('click', () => {
      const accepted = this.checkbox.checked;
      if(accepted) {
        this.privacyManager.updatePreference(true);
      }
    });

    // Need jquery to trigger bootstrap modal
    this.modal = $(`#shorts-slider-modal-${this.id}`);
  }

  initSlider() {
    const sliderNodeId = `#shorts-slider-${this.id}`;
    const numberOfSlides = this.node.querySelectorAll('[data-component-part="shorts-slider-slide"]').length;
    this.swiper = new Swiper(sliderNodeId, {
      modules: [Navigation, Pagination],
      spaceBetween: 30,
      slidesPerView: 1,
      centeredSlides: false,
      slideToClickedSlide: true,
      loop: false,
      mousewheel: {
        enabled: false
      },
      keyboard: {
        enabled: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 4,
        },
        1380: {
          slidesPerView: 5,
        },
      }
    });

    this.node.classList.add('shorts-slider--initialized');
  }

  initModalSlider() {
    const sliderNodeId = `#shorts-modal-slider-${this.id}`;
    this.modalSlider = new Swiper(sliderNodeId, {
      modules: [Navigation, Pagination, Keyboard],
      spaceBetween: 30,
      slidesPerView: 1,
      centeredSlides: true,
      slideToClickedSlide: true,
      loop: false,
      mousewheel: {
        enabled: false
      },
      keyboard: {
        enabled: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      on: {
        afterInit: (swiper) => {
          swiper.slideTo(this.activeIndex, 0, false);
          const video = swiper.slides[this.activeIndex].querySelector('iframe');
          // Start youtube video with postMessage (does not always work)
          video.contentWindow.postMessage(JSON.stringify({
            event: 'command',
            func: 'playVideo'
          }), 'https://www.youtube.com');
        },
        slideChange: (swiper) => {
          // Stop all videos
          const videos = swiper.el.querySelectorAll('iframe');
          videos.forEach((video) => {
            video.contentWindow.postMessage(JSON.stringify({
              event: 'command',
              func: 'stopVideo'
            }), 'https://www.youtube.com');
          });

          // Start youtube video with postMessage
          const video = swiper.slides[swiper.activeIndex].querySelector('iframe');

          video.contentWindow.postMessage(JSON.stringify({
            event: 'command',
            func: 'playVideo'
          }), 'https://www.youtube.com');
        }
      }
    });
  }

  initModals() {
    const slides = this.node.querySelectorAll('[data-component-part="shorts-slider-slide"]');
    this.modalSlider = null;
    this.activeIndex = 0;

    this.modal.on('shown.bs.modal', () => {
      this.initModalSlider();
    });
    // On close, destroy modal swiper, stop videos
    this.modal.on('hidden.bs.modal', () => {
      // Stop all videos
      const videos = this.modalSlider.el.querySelectorAll('iframe');
      videos.forEach((video) => {
        video.contentWindow.postMessage(JSON.stringify({
          event: 'command',
          func: 'stopVideo'
        }), 'https://www.youtube.com');
      });
      this.modalSlider.destroy(true, true);
    });

    let timer;
    slides.forEach((slide, index) => {
      slide.addEventListener('click', () => {
        if (event.detail === 1) {
          timer = setTimeout(() => {
            this.activeIndex = index;
            this.modal.modal('show');
          }, 200)
        }
      });
      slide.addEventListener('dblclick', () => {
        clearTimeout(timer);
      });
    });
  }

  init() {
    this.initSlider();
    this.initModals();
  }

  ready() {
    this.privacyManager.ready();
  }
}
