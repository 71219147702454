// import $ from 'jquery';
// import 'slick-carousel';

const defaultConfig = {
  accessibility: true,
  infinite: false,
  swipeToSlide: true,
  dots: false,
  arrows: true,
  variableWidth: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  // Custom
  containerSelector: '.slider-container',
  nextArrowSelector: '.carousel-control.right',
  prevArrowSelector: '.carousel-control.left',
  appendDotsSelector: '.carousel-control-wrap'
};

function setCarouselControlPosition (slick) {
  let controlPos = slick.$slides.find('img').first().height();
  slick.$slider.siblings('.carousel-control-wrap').css('top', controlPos);
  slick.$slider.parent().siblings('.carousel-control-wrap').css('top', controlPos);
}

function setHiddenAttributeOnSlides (slick) {
  $(slick).find('[aria-hidden=true]').attr('hidden', true);
  $(slick).find('[aria-hidden=false]').removeAttr('hidden');
}

const Slider = (selector, customConfig = {}) => {
  const config = $.extend({}, defaultConfig, customConfig);

  const element = $(selector);
  const container = $(selector).find(config.containerSelector);
  const useElements = container.find('use');
  config.appendDots = $(selector).find(config.appendDotsSelector);

  if (config.arrows) {
    config.nextArrow = element.find(config.nextArrowSelector);
    config.prevArrow = element.find(config.prevArrowSelector);
  }

  container.on('init', (event, slick) => {
    useElements.each((index, item) => {
      if (item.href && item.href.baseVal) {
        item.href.baseVal = item.href.baseVal;
      }
    });
    if (customConfig.centerArrows) {
      setCarouselControlPosition(slick);

      $(window).on('resize', function () {
        // slick.$slider.slick('refresh')
        setCarouselControlPosition(slick);
      });
    }
  });

  container.on('init', function (event, slick) {
    // Initial text for arrow buttons
    slick.$slides.addClass(container.attr('data-bg'));

    // attributes for accessibility
    slick.$slides.attr('aria-roledescription', 'Einzelne Ansicht');
    slick.$slides.attr('role', 'group');
    slick.$slideTrack.attr('role', 'group');
    slick.$slideTrack.attr('aria-roledescription', 'Ringelspiel');

    if (slick.$slides.last().find('.show-more-eventbox').length > 0) {
      slick.$slides.last().removeClass(container.attr('data-bg')).addClass(container.attr('data-show-more-bg'));
    }

    slick.$nextArrow.find('.js-slideCount').text(slick.slideCount);
    slick.$prevArrow.find('.js-slideCount').text(slick.slideCount);
    let slidesShown = $(this).find('[aria-hidden=false]').length;
    let currentSlide = slidesShown;
    let prevSlide = null;
    if (slick.options.infinite) {
      prevSlide = slick.slideCount;
    } else {
      prevSlide = currentSlide;
    }
    let nextSlide = currentSlide + 1;
    let prevTopic = $(slick.$slides[prevSlide - 1]).find('[data-topic]').text();
    let nextTopic = $(slick.$slides[nextSlide - 1]).find('[data-topic]').text();
    slick.$prevArrow.find('.js-slideBefore').text(prevSlide);
    slick.$prevArrow.find('.js-slideTopic').text(prevTopic);
    slick.$nextArrow.find('.js-slideNext').text(nextSlide);
    slick.$nextArrow.find('.js-slideTopic').text(nextTopic);

    setHiddenAttributeOnSlides(this);
  });
  container.slick(config);
  container.on('afterChange', function (event, slick, currentSlide) {
    slick.$slides.addClass(container.attr('data-bg'));
    if (slick.$slides.last().find('.show-more-eventbox').length > 0) {
      slick.$slides.last().removeClass(container.attr('data-bg')).addClass(container.attr('data-show-more-bg'));
    }

    setHiddenAttributeOnSlides(this);

    let slidesShown = $(this).find('[aria-hidden=false]').length;
    if (currentSlide === undefined) {
      currentSlide = slidesShown;
    }
    // Prev Arrow
    if (slick.options.infinite || currentSlide > 0) {
      let prevSlide = currentSlide + slidesShown - 1;
      if (slick.options.infinite) {
        // Loop indexes
        if (prevSlide === 0) {
          prevSlide += slick.slideCount;
        }
      }
      // The setTimeout is used to make sure the screen reader does not read the text changed in the focus button
      // if the focus is shifted by slick to the slide anyways (slick.options.focusOnChange).
      setTimeout(function () {
        let prevTopic = $(slick.$slides[prevSlide - 1]).find('[data-topic]').text();
        slick.$prevArrow.find('.js-slideBefore').text(prevSlide);
        slick.$prevArrow.find('.js-slideTopic').text(prevTopic);
        // If there is no focusOnChange set for slick read the changed text in the focused button
        if (slick.$prevArrow.is(':focus') && !slick.options.focusOnChange) {
          slick.$prevArrow.attr('aria-live', 'polite');
        }
        slick.$prevArrow.html(slick.$prevArrow.html());
        if (slick.$prevArrow.is(':focus') && !slick.options.focusOnChange) {
          slick.$prevArrow.attr('aria-live', 'off');
        }
      });
    }
    // Next Arrow
    let nextSlide = null;
    if (slick.options.infinite || (currentSlide + slidesShown + 1 <= slick.slideCount)) {
      nextSlide = currentSlide + slidesShown + 1;
      if (slick.options.infinite) {
        // Loop indexes
        if (nextSlide === slick.slideCount + 1) {
          nextSlide = 1;
        }
      }
      setTimeout(function () {
        let nextTopic = $(slick.$slides[nextSlide - 1]).find('[data-topic]').text();
        slick.$nextArrow.find('.js-slideNext').text(nextSlide);
        slick.$nextArrow.find('.js-slideTopic').text(nextTopic);
        if (slick.$nextArrow.is(':focus') && !slick.options.focusOnChange) {
          slick.$nextArrow.attr('aria-live', 'polite');
        }
        slick.$nextArrow.html(slick.$nextArrow.html());
        if (slick.$nextArrow.is(':focus') && !slick.options.focusOnChange) {
          slick.$nextArrow.attr('aria-live', 'off');
        }
      }, 0);
    }
  });
};

export { Slider };
