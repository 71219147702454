class NavPanel {
  constructor (element, handle) {
    this.panel = element;
    this.handle = handle;
    this.isOpen = element.hasClass('is-open');
    this.focusedElementBeforeOpen = null;
    this.mainContent = $('main');
    this.header = $('.site-header');
    this.bodyContent = $('body');
    this.footerContent = $('.footer');
    this.footerLegalContent = $('.footer-legal');

    this.addListeners();
  }

  addListeners () {
    this.panel.find('[data-close]').each((index, item) => {
      item.addEventListener('click', this.close.bind(this));
      item.addEventListener('keyup', e => e.keyCode === 32 && this.close());
    });

    // $(document).keyup(e => e.keyCode === 27 && this.close())
    $(document).off(`toggle-${this.handle}`);
    $(document).off(`toggle-open-${this.handle}`);
    $(document).off(`toggle-close-${this.handle}`);
    // $(document).off('close-overlays')
    $(document).on(`toggle-${this.handle}`, this.toggle.bind(this));
    $(document).on(`toggle-open-${this.handle}`, this.open.bind(this));
    $(document).on(`toggle-close-${this.handle}`, this.close.bind(this));
    $(document).on('close-overlays', (e, sourceNode) => {
      if (!this.panel.get(0).isEqualNode(sourceNode) && this.isOpen) {
        this.close();
      }
    });

    $(document).on('click', (e) => {
      if ($(e.target).closest('.nav-panel').length === 0 && $(e.target).closest('.banner').length === 0) {
        this.close();
      }
    });

    var that = this;
    $(document).on('keydown', (e) => {
      var keyCode = e.keyCode || e.which;

      if (keyCode === 9 && that.isOpen) {
        var selectableElements = that.panel.find('input,select,textarea,button,object,a[href]').filter(':not(:disabled)');
        var lastElement = selectableElements.last();

        if (!$.contains(that.panel[0], e.target) || lastElement[0] === e.target) {
          selectableElements.first().focus();
          e.preventDefault();
        }
      }
    });
  }

  toggle () {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  close () {
    this.isOpen = false;
    this.panel.removeClass('is-open');
    this.panel.fadeOut(150);
    this.panel.attr('aria-hidden', true);

    this.bodyContent.removeClass('no-scroll');
    this.header.removeClass('open');
    this.mainContent.removeClass('nav-panel-overlay');
    this.mainContent.attr('aria-hidden', false);
    this.footerContent.attr('aria-hidden', false);
    this.footerLegalContent.attr('aria-hidden', false);

    if (this.panel.attr('id') === 'navPanel-navigation') {
      this.mainContent.removeClass('nav-panel-overlay-full');
    }

    if (this.focusedElementBeforeOpen) {
      this.focusedElementBeforeOpen.focus();
      this.focusedElementBeforeOpen = null;
    }

    $(document).trigger(`close-${this.handle}`);
  }

  open () {
    $(document).trigger('close-overlays', this.panel);

    this.bodyContent.addClass('no-scroll');
    this.header.addClass('open');
    this.mainContent.attr('aria-hidden', true);
    this.mainContent.addClass('nav-panel-overlay');
    this.footerContent.attr('aria-hidden', true);
    this.footerLegalContent.attr('aria-hidden', true);

    this.isOpen = true;
    this.panel.addClass('is-open');
    this.panel.fadeIn(150);
    this.panel.attr('aria-hidden', false);
    this.focusedElementBeforeOpen = $(':focus');
    this.panel.filter(':visible').focusWithoutScrolling();

    if (this.panel.attr('id') === 'navPanel-navigation') {
      this.mainContent.addClass('nav-panel-overlay-full');
    }

    $(document).trigger(`open-${this.handle}`);
  }
}

export {NavPanel};
export default NavPanel;
