import Swiper, {HashNavigation, Navigation, Pagination} from "swiper";

export default class C044StorySlider {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.id = props.id;
    this.number = 0;
    this.paused = null;

    this.lastScrollY = 0;
  }

  initHeight() {
    const mapContainer = this.node.querySelector('.story-slider__map-inner');
    const containerWidth = mapContainer.offsetWidth;
    const containerHeight = mapContainer.offsetHeight;
    const multiplier = containerWidth / containerHeight / 1.2;
    mapContainer.style.setProperty('--spot-pos-y-multiplier', multiplier);
  }

  init() {
    this.lastScrollY = window.scrollY;

    window.addEventListener('scroll', () => {
      if (
        window.scrollY > window.innerHeight &&
        window.scrollY > this.lastScrollY &&
        this.lastScrollY > 0
      ) {
        document.body.classList.remove('body--show-stories');
      }
      this.lastScrollY = window.scrollY;
    });
    if (window.location.hash === '#site-header') {
      document.body.classList.remove('body--show-stories');
    }
    this.initHeight();
    this.initSlider();

    window.addEventListener('resize', () => {
      this.initHeight();
    });

    const skipLink = document.querySelector('[data-component-part="skip-link"]');
    skipLink.addEventListener('click', (e) => {
      e.preventDefault();
      window.scroll({
        top: window.innerHeight,
        left: 0,
        behavior: 'smooth'
      });
    });

    const backLink = document.getElementById('story-slider-back');
    backLink.addEventListener('click', (e) => {
      e.preventDefault();
      document.body.classList.add('body--show-stories');
      // Jump under the slider
      window.scroll({
        top: window.innerHeight,
        left: 0,
        behavior: 'instant'
      });

      setTimeout(() => {
        this.initHeight();
        this.node.scrollIntoView({
          behavior: 'smooth'
        });
      }, 100);
    });
  }

  initSlider() {
    const sliderNodeId = `#story-slider-${this.id}`;

    this.swiper = new Swiper(sliderNodeId, {
      modules: [Navigation, Pagination, HashNavigation],
      slidesPerView: 1,
      centeredSlides: false,
      slideToClickedSlide: true,
      loop: true,
      hashNavigation: {
        watchState: true
      },
      mousewheel: {
        enabled: false
      },
      keyboard: {
        enabled: true
      },
      navigation: {
        nextEl: '.story-slider__slider-controls-next',
        prevEl: '.story-slider__slider-controls-prev'
      },
      on: {
        beforeInit: (swiper) => {
          this.number = swiper.el.querySelectorAll('[data-component-part="story-slider-slide"]').length;
        },
        init: (swiper) => {
          swiper.slides[swiper.activeIndex].classList.add('story-slider-bounce');
          this.videoControl = this.node.querySelector('.story-slider__slider-controls-play-pause');

          this.videoControl.addEventListener('click', () => {
            const srInfo = this.videoControl.querySelector('.sr-only');

            if (this.videoElement.paused) {
              this.videoElement.play();
              this.paused = false;
              this.videoControl.classList.remove('story-slider__slider-controls-play-pause--paused');
              srInfo.textContent = srInfo.dataset['data-label-pausing'];
            } else {
              this.videoElement.pause();
              this.paused = true;
              this.videoControl.classList.add('story-slider__slider-controls-play-pause--paused');
              srInfo.textContent = srInfo.dataset['data-label-playing'];
            }
          });

        },
        slideChange: (swiper) => {
          const activeIndex = swiper.activeIndex;
          const realIndex = swiper.realIndex;
          const slide = swiper.slides[activeIndex];
          if (!slide) {
            return;
          }
          const slideId = slide.getAttribute('id');
          const hasShadow = slide.getAttribute('data-shadow') === '1';
          this.videoElement = slide.querySelector('video');
          const otherVideos = [...this.node.querySelectorAll('video')].filter(v => v !== this.videoElement);
          const spotId = slideId.replace('story-slider__slide-', 'story-slider__map-group-');
          const contentId = slideId.replace('story-slider__slide-', 'story-slider__slider-content-');

          const posControl = this.node.querySelector('.story-slider__slider-controls-pos');
          if (posControl) {
            posControl.textContent = `${realIndex + 1} / ${this.number}`;
          }

          if (hasShadow) {
            swiper.el.classList.add('swiper-wrapper--shadow');
          } else {
            swiper.el.classList.remove('swiper-wrapper--shadow');
          }

          this.videoControl = this.node.querySelector('.story-slider__slider-controls-play-pause');
          if (this.videoElement) {
            this.videoControl.classList.add('story-slider__slider-controls-play-pause--active');

            // Initial slide
            if (this.paused === null) {
              this.paused = false;
              this.videoControl.classList.toggle('story-slider__slider-controls-play-pause--paused', this.paused);
              this.videoElement.play();
            } else {
              if (this.paused) {
                this.videoElement.pause();
              } else {
                this.videoElement.play();
              }
              otherVideos.forEach(v => v.pause());
            }
          } else {
            this.videoControl.classList.remove('story-slider__slider-controls-play-pause--active');
          }

          const spotGroup = document.getElementById(spotId);
          spotGroup.classList.add('story-slider__map-group--active');

          [...document.querySelectorAll('.story-slider__map-group')]
            .filter(s => s !== spotGroup)
            .forEach(s => s.classList.remove('story-slider__map-group--active'));

          const contentGroup = document.getElementById(contentId);
          contentGroup.classList.add('story-slider__slider-content--active');

          const contentGroupDesktop = document.getElementById(contentId + '-desktop');
          contentGroupDesktop.classList.add('story-slider__slider-content--active');

          [...document.querySelectorAll('.story-slider__slider-content')]
            .filter(s => s !== contentGroup && s !== contentGroupDesktop)
            .forEach(s => s.classList.remove('story-slider__slider-content--active'));
        }
      }

    });

    this.node.classList.add('shorts-slider--initialized');
  }

  ready() {
    this.init();
  }
}
