class BackgroundVideo {
  constructor (element, config) {
    this.container = $(element);
    this.navButton = $('header .nav-button');
    this.video = this.container.find('[data-video]');
    this.videoControl = this.container.find('[data-video-control]');
    this.playing = false;
    this.autoplay = window.matchMedia('(min-width: 968px)').matches;
    this.autoplay_set = (this.container.find('[play]').length > 0 ? 'true' : 'false');
    this.accessNav = $('.accessibility-navigation');
    this.globalButton = $('button');
    this.init();
  }

  init () {
    this.accessibility();

    if (this.videoControl) {
      this.video.on('playing', () => {
        this.playing = true;
        this.updateButtonState();
      });

      this.video.on('pause', () => {
        this.playing = false;
        this.updateButtonState();
      });

      this.videoControl.on('click', (e) => {
        this.onPressVideoControl(e);
      });
    }

    $(this.navButton).on('click', () => {
      this.pause();
    });

    $(this.accessNav).on('click', () => {
      this.pause();
    });

    $(this.globalButton).on('click', () => {
      if (!$(this).has('btn-video-control')) {
        this.pause();
      }
    });
    this.checkAutoplay();
  }

  checkAutoplay() {
    const autoplayPromise = this.video.get(0).play();
    if (autoplayPromise !== undefined) {
      autoplayPromise.then(() => {
        // Autoplay started
        this.playing = true;
        this.updateButtonState();
      }).catch(() => {
        // Autoplay prevented
        this.playing = false;
        this.updateButtonState();
      });
    }
  }

  onPressVideoControl (e) {
    e.preventDefault();

    if (this.playing) {
      this.pause();
    } else {
      this.play();
    }
  }

  play() {
    const video = this.video.get(0);
    video.play();
  }

  pause() {
    const video = this.video.get(0);
    video.pause();
  }

  updateButtonState() {
    const videoControlIcon = this.videoControl.find('[data-video-icon]:first');
    const videoControlLabel = this.videoControl.find('[data-video-label]:first');

    if (this.playing) {
      const iconName = videoControlIcon.attr('data-icon-playing');
      const icon = videoControlIcon.find('svg').clone();
      icon.find('use').attr('xlink:href', iconName);
      videoControlIcon.empty().append(icon);
      videoControlLabel.html(videoControlLabel.attr('data-label-playing'));
    } else {
      const iconName = videoControlIcon.attr('data-icon-pausing');
      const icon = videoControlIcon.find('svg').clone();
      icon.find('use').attr('xlink:href', iconName);
      videoControlIcon.empty().append(icon);
      videoControlLabel.html(videoControlLabel.attr('data-label-pausing'));
    }
  }

  accessibility () {
    if (window.matchMedia('(prefers-reduced-motion)').matches) {
      this.video.removeAttr('autoplay');
      this.pause();
    } else if (!this.playing && this.autoplay && this.autoplay_set === 'true') {
      this.play();
    } else {
      this.video.attr('preload', 'none');
    }
  }
}

export { BackgroundVideo };
export default BackgroundVideo;
