import $ from "jquery";

export default class C001Accordion {
  constructor(node, props) {
    this.node = node;
    this.props = props;
  }

  init() {
    const accordionItems = this.node.querySelectorAll('[data-component-part="item"]');
    accordionItems.forEach((item) => {
      const $button = $('.panel-heading .panel-title button', item);

      $button.on('click', () => {
        let scrollToElement;
        // active item has to be saved globally because component is destroyed on cms refresh
        window.activeAccordionItem = item.id;
        if ($button.hasClass('collapsed')) {
          scrollToElement = this.getScrollToElement(this.node);
          if(scrollToElement) {
            window.setTimeout(() => {
              history.replaceState(null, null, window.location.pathname + window.location.search);
              window.scrollTo({
                top: scrollToElement.getBoundingClientRect().top + window.scrollY - 100,
                behavior: 'smooth',
              });
            }, 350);
          }
        } else {
          window.activeAccordionItem = null;
        }
      })

      $button.on('keyup', function (event) {
        if (event.which === 32 || event.which === 13) {
          $('[class*=button_close]').click();
          this.click();
          return false;
        }
      });
    });

    this.triggerHash();

    window.addEventListener('hashchange', this.triggerHash.bind(this));
  }

  getScrollToElement(accordionItem) {
    let anchorElement = this.getAnchorElement();
    if (anchorElement && anchorElement.length && this.isElementInPanel(anchorElement)) {
      return anchorElement[0];
    }
    else {
      return accordionItem;
    }
  }

  triggerHash() {
    let $elem = this.getAnchorElement();

    if (!$elem || !$elem.length) {
      return;
    }

    if ($elem.hasClass('panel') && $elem.parent().hasClass('accordion')) {
      let $panelButton = $('.panel-heading .panel-title button', $elem);

      if ($panelButton.length && $panelButton.hasClass('collapsed')) {
        $panelButton.click();
      }
    }
    else if (this.isElementInPanel($elem)) {
      let $panelButton = $('.panel-heading .panel-title button', $elem.closest('.panel'));

      if ($panelButton.length && $panelButton.hasClass('collapsed')) {
        $panelButton.click();
      }
    }
  }

  getAnchorElement() {
    let hash = document.location.hash;
    if(!hash && window.activeAccordionItem) {
      hash = '#' + window.activeAccordionItem;
    }

    if (!hash) {
      return;
    }
    return $(hash);
  }

  isElementInPanel(element) {
    return element.closest('.panel').length;
  }

  ready() {
    this.init();
  }

  clear() {
    // There seems to be no destroy method for bootstrap accordion
  }

  cmsRefresh() {
    this.init();
  }
}
