export class U016SingleSocialMediaPostFacebook {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.loaded = false;
    this.id = this.node.parentElement.parentElement.dataset.contentUid;

    window.django_privacy_mgmt.addListener(
      "change",
      this.handleConsentChange.bind(this)
    );
  }

  addScript() {
    if (window.U016SingleSocialMediaPostFacebookScriptLoaded) {
      return;
    } else {
      window.U016SingleSocialMediaPostFacebookScriptLoaded = true;
    }

    //<script async defer crossorigin="anonymous" src="https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v20.0" nonce="hRqRjPbX"></script>

    const scriptEl = document.createElement("script");

    scriptEl.setAttribute("async", "");
    scriptEl.setAttribute("defer", "");
    scriptEl.setAttribute("crossorigin", "anonymous");
    scriptEl.setAttribute(
      "src",
      "https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v20.0"
    );
    scriptEl.setAttribute("nonce", "hRqRjPbX");

    document.body.appendChild(scriptEl);
  }

  loadPluginContent() {
    const post = this.node.querySelector("._fb-post");
    if (post) {
      post.classList.remove("_fb-post");
      post.classList.add("fb-post");
    }

    let editorPlaceholder = this.node.querySelector(".socialmedia-editor-placeholder");
    if (editorPlaceholder) {
      editorPlaceholder.style.display = "none";
    }
  }

  isContentApproved() {
    return (
      this.node.parentElement.dataset.privacyContentApproved === "approved"
    );
  }

  ready() {
    if (this.isContentApproved()) {
      this.loadPluginContent();
      this.loaded = true;

      window.setTimeout(() => {
        this.addScript();
      }, 250);
    }
  }

  handleConsentChange(preferences) {
    if (
      window.django_privacy_mgmt.getPreference("SOCIAL_MEDIA") ||
      window.django_privacy_mgmt.getPreference("FACEBOOK_POSTS") ||
      preferences[this.id]
    ) {
      if (!this.loaded) {
        if (window.U016SingleSocialMediaPostFacebookScriptLoaded) {
          window.location.reload();
        } else {
          window.setTimeout(() => {
            this.ready();
          }, 250);
        }
      }
    }
  }
}

export class U016SingleSocialMediaPostInstagram {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.loaded = false;
    this.id = this.node.parentElement.parentElement.dataset.contentUid;

    window.django_privacy_mgmt.addListener(
      "change",
      this.handleConsentChange.bind(this)
    );
  }

  addScript() {
    if (window.U016SingleSocialMediaPostInstagramScriptLoaded) {
      return;
    } else {
      window.U016SingleSocialMediaPostInstagramScriptLoaded = true;
    }

    //<script async src="//www.instagram.com/embed.js"></script>

    const scriptEl = document.createElement("script");

    scriptEl.setAttribute("async", "");
    scriptEl.setAttribute("src", "//www.instagram.com/embed.js");

    document.body.appendChild(scriptEl);
  }

  loadPluginContent() {
    const placeholder = this.node.querySelector("._instagram-media");
    if (placeholder) {
      placeholder.classList.remove("_instagram-media");
      placeholder.classList.add("instagram-media");
    }

    let editorPlaceholder = this.node.querySelector(".socialmedia-editor-placeholder");
    if (editorPlaceholder) {
      editorPlaceholder.style.display = "none";
    }
  }

  isContentApproved() {
    return (
      this.node.parentElement.dataset.privacyContentApproved === "approved"
    );
  }

  ready() {
    if (this.isContentApproved()) {
      this.loadPluginContent();
      this.loaded = true;
      window.setTimeout(() => {
        this.addScript();
      }, 250);
    }
  }

  handleConsentChange(preferences) {
    if (
      window.django_privacy_mgmt.getPreference("SOCIAL_MEDIA") ||
      window.django_privacy_mgmt.getPreference("INSTAGRAM_POSTS") ||
      preferences[this.id]
    ) {
      if (!this.loaded) {
        if (window.U016SingleSocialMediaPostInstagramScriptLoaded) {
          window.location.reload();
        } else {
          window.setTimeout(() => {
            this.ready();
          }, 250);
        }
      }
    }
  }
}

export class U016SingleSocialMediaPostX {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.loaded = false;
    this.id = this.node.parentElement.parentElement.dataset.contentUid;

    window.django_privacy_mgmt.addListener(
      "change",
      this.handleConsentChange.bind(this)
    );
  }

  addScript() {
    if (window.U016SingleSocialMediaPostXScriptLoaded) {
      return;
    } else {
      window.U016SingleSocialMediaPostXScriptLoaded = true;
    }

    //<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

    const scriptEl = document.createElement("script");

    scriptEl.setAttribute("async", "");
    scriptEl.setAttribute("src", "https://platform.twitter.com/widgets.js");
    scriptEl.setAttribute("charset", "utf-8");

    document.body.appendChild(scriptEl);
  }

  loadPluginContent() {
    const placeholder = this.node.querySelector("._twitter-tweet");
    if (placeholder) {
      placeholder.classList.remove("_twitter-tweet");
      placeholder.classList.add("twitter-tweet");
    }

    let editorPlaceholder = this.node.querySelector(".socialmedia-editor-placeholder");
    if (editorPlaceholder) {
      editorPlaceholder.style.display = "none";
    }
  }

  isContentApproved() {
    return (
      this.node.parentElement.dataset.privacyContentApproved === "approved"
    );
  }

  ready() {
    if (this.isContentApproved()) {
      this.loadPluginContent();
      this.loaded = true;
      window.setTimeout(() => {
        this.addScript();
      }, 250);
    }
  }

  handleConsentChange(preferences) {
    if (
      window.django_privacy_mgmt.getPreference("SOCIAL_MEDIA") ||
      window.django_privacy_mgmt.getPreference("X_POSTS") ||
      preferences[this.id]
    ) {
      if (!this.loaded) {
        if (window.U016SingleSocialMediaPostXScriptLoaded) {
          window.location.reload();
        } else {
          window.setTimeout(() => {
            this.ready();
          }, 250);
        }
      }
    }
  }
}

export class U016SingleSocialMediaPostLinkedin {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.loaded = false;
    this.id = this.node.parentElement.parentElement.dataset.contentUid;

    window.django_privacy_mgmt.addListener(
      "change",
      this.handleConsentChange.bind(this)
    );
  }

  loadPluginContent() {
    const { src, height } = this.node.dataset;
    const placeholder = this.node.querySelector(".linkedin-placeholder-div");

    if (placeholder) {
      placeholder.innerHTML = `
            <iframe
                src="${src}"
                height="${height}"
                width="504"
                frameborder="0"
                allowfullscreen=""
                title="Eingebetteter Beitrag"
                class="privacy-content"
            ></iframe>`;
    }

    let editorPlaceholder = this.node.querySelector(".socialmedia-editor-placeholder");
    if (editorPlaceholder) {
      editorPlaceholder.style.display = "none";
    }
  }

  isContentApproved() {
    return (
      this.node.parentElement.dataset.privacyContentApproved === "approved"
    );
  }

  ready() {
    if (this.isContentApproved()) {
      this.loadPluginContent();
      this.loaded = true;
    }
  }

  handleConsentChange(preferences) {
    if (
      window.django_privacy_mgmt.getPreference("SOCIAL_MEDIA") ||
      window.django_privacy_mgmt.getPreference("LINKEDIN_POSTS") ||
      preferences[this.id]
    ) {
      if (!this.loaded) {
        window.setTimeout(() => {
          this.ready();
        }, 250);
      }
    }
  }
}
