/**
 * Based on Cl.Locations by Angelo Dini
 */
/* global google, CMS */
import $ from 'jquery'

class Locations {
    constructor(container, options) {
        // has to be inside because `google` isn't defined at load time
        this.defaults = {
            // eslint-disable-next-line camelcase
            edit_mode: false,
            mapType: '',
            mapTypeControlOptions: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            markers: [],
            markerAnimation: google.maps.Animation.DROP,
            routePlanner: false,
            settings: {},
            zoom: 12,
            zoomControlOptions: google.maps.ZoomControlStyle.SMALL,
            cls: {
                mapContainer: '.aldryn-locations-container',
                formContainer: '.aldryn-locations-form',
                formOutput: '.aldryn-locations-form-output'
            }
        }

        this.container = $(container)
        this.options = $.extend(true, {}, this.defaults, options)
        this.settings = this.options.settings
        this.markers = []
        this.bounds = new google.maps.LatLngBounds()
        this.form = this.container.find(this.options.cls.formContainer)

        // presets
        this.mapTypes = {
            hybrid: google.maps.MapTypeId.HYBRID,
            roadmap: google.maps.MapTypeId.ROADMAP,
            satellite: google.maps.MapTypeId.SATELLITE,
            terrain: google.maps.MapTypeId.TERRAIN
        }

        // move to setup
        this._setup()
    }

    _setup() {
        // extend google map settings
        this.settings.mapTypeId = this.mapTypes[this.options.mapType]
        this.settings.zoomControlOptions = { style: this.options.zoomControlOptions }
        this.settings.mapTypeControlOptions = { style: this.options.mapTypeControlOptions }

        // setting up map
        this.mapContainer = this.container.find(this.options.cls.mapContainer)[0]
        this.map = new google.maps.Map(this.mapContainer, this.settings)

        // add all markers
        this.addMarkers(this.options.markers)
        this.addLayers(this.options.layerSources)

        // enable routing
        this._routePlanner()
    }

    /**
     * Adds a collection of markers to the google map instance
     *
     * @param {Object[]} markers exptects a collection of markers (see addMarker())
     */
    addMarkers(markers) {
        var that = this

        /**
         * geocode helper
         *
         * @param {google.map.Marker} marker marker
         */
        function geocode(marker) {
            var geocoder = new google.maps.Geocoder()

            geocoder.geocode({ address: marker.address }, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    marker.latlng = results[0].geometry.location
                    that.addMarker(marker)
                }
            })
        }

        // loop through all given markers
        for (var i = 0; i < markers.length; i++) {
            var marker = markers[i]

            //  check if we need to calculate the latlng or continue
            if (marker.latlng === null) {
                geocode(marker)
            } else {
                marker.latlng = new google.maps.LatLng(marker.latlng[0], marker.latlng[1])
                this.addMarker(marker)
            }
        }
    }

    /**
     * Adds KLM layers
     *
     * @param {String[]} layerSources sources
     */
    addLayers(layerSources) {
        if (!layerSources || !layerSources.length) {
            return
        }

        layerSources.forEach((src) => {
            // eslint-disable-next-line no-new
            new google.maps.KmlLayer(src, {
                suppressInfoWindows: true,
                preserveViewport: false,
                map: this.map
            })
        })
    }

    /**
     * adds a single marker to the google map instance
     *
     * @param {Object} marker  marker object
     * @param {String} marker.address expects a valid address
     * @param {String} marker.admin admin link for editing within the cms
     * @param {String} [marker.content] content for the markerInfoWindow
     * @param {Number} [marker.latlng] expects valid lat and lng values
    */
    addMarker(marker) {
        var that = this

        // Creates a marker on the map, calls fitMap afterwards
        // eslint-disable-next-line no-param-reassign
        marker = new google.maps.Marker({
            map: this.map,
            admin: marker.admin,
            position: marker.latlng,
            title: marker.title,
            content: marker.content
        })

        // attach info window
        if (marker.content) {
            var infoWindow = new google.maps.InfoWindow({
                disableAutoPan: true,
                // to false and InfoWindow is out of bounds
                content: marker.content
            })

            google.maps.event.addListener(marker, 'click', function () {
                infoWindow.open(that.map, marker)
                marker.setAnimation(google.maps.Animation.BOUNCE)
                setTimeout(function () {
                    marker.setAnimation(null)
                }, 750) // eslint-disable-line no-magic-numbers
            })

            if (this.options.markers.length === 1) {
                setTimeout(function () {
                    infoWindow.open(that.map, marker)
                }, 500) // eslint-disable-line no-magic-numbers
            }
        }

        // add admin edit capabilities for markers
        if (this.options.edit && window.CMS) {
            google.maps.event.addListener(marker, 'dblclick', function () {
                setTimeout(function () {
                    var modal = new CMS.Modal()

                    modal.open({
                        url: marker.admin
                    })
                }, 0.3) // eslint-disable-line
            })
        }

        // update markers and map position
        this.markers.push(marker)
        this.bounds.extend(marker.position)
        this.map.fitBounds(this.bounds)
        this.map.panBy(0, -50) // eslint-disable-line no-magic-numbers

        // reassign zoom level
        var listener = google.maps.event.addListener(that.map, 'idle', function () {
            if (that.map.getZoom() > that.options.zoom) {
                that.map.setZoom(that.options.zoom)
            }
            google.maps.event.removeListener(listener)
        })
    }

    _routePlanner() {
        var that = this
        var mode = ''
        var destination = this.form.find('input[type="hidden"]').val()
        var routers = this.container.find('span[data-type]')
        var directionsService = new google.maps.DirectionsService()
        var directionsDisplay = new google.maps.DirectionsRenderer()

        directionsDisplay.setMap(this.map)
        directionsDisplay.setPanel(this.container.find(this.options.cls.formOutput)[0])

        /**
         * getRoute
         * @private
         */
        function getRoute() {
            var origin = that.form.find('input[type="text"]').val()

            // hide streetview
            that.map.getStreetView().setVisible(false)

            var request = {
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode[mode.toUpperCase()]
            }

            directionsService.route(request, function (result, status) {
                if (status === google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setMap(that.map)
                    directionsDisplay.setDirections(result)
                    that.form.find('.form-group').removeClass('has-error')
                } else {
                    that.form.find('.form-group').addClass('has-error')
                }
            })
        }

        // attach routes handling
        routers.on('click', function () {
            routers.removeClass('active')
            $(this).addClass('active')
            mode = $(this).data('type')
        }).eq(0).trigger('click')

        // prevent form submission
        this.form.on('submit', function (e) {
            e.preventDefault()
            getRoute()
        })
    }
}

/**
 * @function initGoogleMaps
 * @param {String|jQuery} [selector='.js-aldryn-locations'] selector or jquery collection to init map
 */
export function initGoogleMaps(selector = '.js-aldryn-locations') {
    var maps = $(selector)

    if (!maps.length) {
        return
    }

    // wait for google maps api to load
    $(window).on('load', function () {
        // last failcheck
        if (!google || !google.maps) {
            return
        }

        maps.each(function () {
            var mapElement = $(this)
            var mapData = mapElement.data('googleMap')

            mapElement.data('locations', new Locations(mapElement, mapData))
        })
    })
}
