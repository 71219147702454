import U010AudioPlayer from "./u010-audio-player.js";

export default class U015Hotspots {
  constructor(node, props, loader) {
    this.node = node;
    this.props = props;
    this.id = props.id;
    this.loader = loader;
  }

  toggleHotspot(hotspot) {
    hotspot.classList.toggle('hotspot--active');
    [...this.hotspots].filter(h => h !== hotspot)
      .forEach(h => h.classList.remove('hotspot--active'));
    if (!hotspot.classList.contains('hotspot--active')) {
      return;
    }

    const tooltip = hotspot.nextElementSibling;
    if (tooltip && tooltip.classList.contains('hotspot__tooltip')) {
      const width = tooltip.offsetWidth;
      const windowWidth = window.innerWidth;

      // Right aligned
      if (tooltip.style.right) {
        let shift = -width / 2;
        const right = windowWidth - hotspot.getBoundingClientRect().right;

        if (right < width / 2) {
          shift = -right + 12;
        }
        tooltip.style.setProperty('--shift-right', `${shift}px`);
        tooltip.style.setProperty('--shift-arrow-right', `${-shift - 9}px`);
      } else {
        const left = hotspot.getBoundingClientRect().left;
        let shift = -width / 2;
        if (left + shift < 0) {
          shift = -left;
        }
        tooltip.style.setProperty('--shift', `${shift}px`);
        tooltip.style.setProperty('--shift-arrow', `${-shift - 9}px`);
      }

    }

    const target = hotspot.getAttribute('href');
    const targetEl = document.querySelector(target);
    if (!targetEl) return;

    targetEl.classList.toggle('hotspot-modal--active');
    targetEl.focus();
    document.body.classList.add('modal-open');
  }

  closeModal(hotspot_modal) {
    hotspot_modal.classList.remove('hotspot-modal--active');
    const hotspot = document.querySelector(`[href="#${hotspot_modal.id}"]`);
    hotspot.classList.remove('hotspot--active');
    document.body.classList.remove('modal-open');

    this.loader.getComponentsByType(U010AudioPlayer).forEach((audioPlayer) => {
      audioPlayer.stop();
    });
  }
  init() {
    this.hotspots = this.node.querySelectorAll('[data-component-part="hotspot"]');
    let timer = null;
    this.hotspots.forEach((hotspot) => {
      hotspot.addEventListener('click', (e) => {
        e.preventDefault();
        if (event.detail === 1) {
          clearTimeout(timer);
          timer = setTimeout(() => {
            this.toggleHotspot(hotspot);
          }, 200);
        }
      });
      hotspot.addEventListener('dblclick', () => {
        // If edit mode, prevent double click
        if (window.CMS) {
          clearTimeout(timer);
        }
      });
      hotspot.addEventListener('keyup', (e) => {
        e.preventDefault();
        if (e.keyCode === 13) {
          this.toggleHotspot(hotspot);
        }
      });
    });

    const hotspot_modals = document.querySelectorAll('[data-component-part="hotspot-modal"]');
    hotspot_modals.forEach((hotspot_modal) => {
      hotspot_modal.addEventListener('keyup', (e) => {
        e.preventDefault();
        if (e.keyCode === 27) {
          this.closeModal(hotspot_modal);
        }
      });
      hotspot_modal.addEventListener('click', (e) => {
        if (e.target !== hotspot_modal) return;
        this.closeModal(hotspot_modal);
      });
      const modal_close = hotspot_modal.querySelector('[data-component-part="hotspot-modal-close"]');
      modal_close.addEventListener('click', (e) => {
        e.preventDefault();
        this.closeModal(hotspot_modal);
      });
    });
  }

  ready() {
    this.init();
  }
}
