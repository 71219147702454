window.addEventListener('load', function (event) {
  $('form input').on('invalid', function () {
    const first = $(this.form).find(':invalid')[0];
    smoothScrollIntoView(first);
  });
});

function smoothScrollIntoView (element, marginTop = 15) {
  const stickyHeader = document.getElementsByClassName('site-header')[0];
  const cmsToolbar = document.getElementsByClassName('cms-toolbar')[0];
  const headerOffset = getOuterHeight(stickyHeader) + getOuterHeight(cmsToolbar) + marginTop || 0;
  const targetElementTop = element.getBoundingClientRect().top;
  const offsetPosition = targetElementTop + window.scrollY - headerOffset;

  const pageOffset = window.pageYOffset - headerOffset;
  const pageelementOffset = $(element).offset().top - headerOffset;
  // don't scroll if the element is already in view
  if (pageelementOffset > pageOffset && pageelementOffset < pageOffset + window.innerHeight) {
    return true;
  }

  window.scrollTo({top: offsetPosition});
}

function getOuterHeight (el) {
  el = (typeof el === 'string') ? document.querySelector(el) : el;
  if (!el) {
    return 0;
  }
  const styles = window.getComputedStyle(el);
  const margin = parseFloat(styles['marginTop']) +
    parseFloat(styles['marginBottom']);
  return Math.ceil(el.offsetHeight + margin);
}
