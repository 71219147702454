import $ from 'jquery';
import Swiper, {Navigation, Pagination} from 'swiper';

const initAccessibilitySlider = (element) => {
  const swiperId = element.querySelector('.swiper-container').id;
  const swiper = new Swiper('#' + swiperId, {
    modules: [Navigation, Pagination],
    initialSlide: 1,
    spaceBetween: 30,
    slidesPerView: 'auto',
    centeredSlides: true,
    slideToClickedSlide: true,
    loop: true,
    loopedSlides: 3,
    grabCursor: true,
    mousewheel: {
      enabled: false
    },
    keyboard: {
      enabled: true
    },
    pagination: {
      el: '.swiper-pagination'
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });
  window.setTimeout(() => {
    $('.swiper-slide-duplicate .card-body div:nth-child(2)').attr('id', '');
  }, 500);
};

export {
  initAccessibilitySlider
};
