export default class Loader {
  constructor() {
    this.componentClasses = {};
    this.components = [];
    this.register.bind(this);
    this.init.bind(this);
  }

  register(name, component) {
    this.componentClasses[name] = component;
  }

  getComponentsByType(type) {
    return this.components.filter((component) => {
      return component instanceof type;
    });
  }

  findProps(node) {
    let result = {};
    if (node.getAttribute('data-component-var')) {
      const name = node.getAttribute('data-component-var');
      const val = node.innerHTML;
      result[name] = val;
    }
    if (node.getAttribute('data-component-json')) {
      const name = node.getAttribute('data-component-json');
      try {
        const val = JSON.parse(node.innerHTML);
        result[name] = val;
      } catch (error) {
        console.error(`Error: ${name}`);
        console.error(`${error.name}: ${error.message}`);
      }
    }
    if (node.getAttribute('data-component-list')) {
      const name = node.getAttribute('data-component-list');
      let list = [...node.children].map((child) => {
        return this.findProps(child);
      })
      result[name] = list;
    }
    if (Object.keys(result).length === 0) { //No config here, descend
      const childProps = Object.assign({}, ...[...node.children].map((child) => {
        return this.findProps(child);
      }));
      result = Object.assign(result, childProps);
    }
    return result;
  }

  init(dom) {
    const componentNodes = [...dom.querySelectorAll('[data-component]')];

    componentNodes.forEach((node) => {
      const componentName = node.getAttribute('data-component');

      const ComponentClass = this.componentClasses[componentName];
      if (typeof ComponentClass === 'undefined') {
        console.error("Component " + componentName + " not defined.");
        return;
      }

      const props = this.findProps(node);

      const component = new ComponentClass(node, props, this);

      this.components.push(component);
    });
  }

  ready() {
    this.components.forEach((component) => {
      if (typeof component.ready === 'undefined') {
        return;
      }
      component.ready();
    });
  }

  clear() {
    this.components.forEach((component) => {
      if (typeof component.clear === 'undefined') {
        return;
      }
      component.clear();
    });
    this.components = [];
  }

  windowResize() {
    this.components.forEach((component) => {
      if (typeof component.windowResize === 'undefined') {
        return;
      }
      component.windowResize();
    });
  }

  cmsRefresh() {
    this.clear();
    this.init(window.document);
    this.ready();
  }
}
