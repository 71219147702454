const Banner = (banner = $('.banner'), threshold = 100) => {
  const navPanels = $('.nav-panel');
  $(document).on('scroll', () => {
    if ($(document).scrollTop() > threshold) {
      if($(document.body).hasClass('body--show-stories'))
        return;
      banner.addClass('small');
      navPanels.each((index, panel) => {
        $(panel).addClass('small');
      });
    } else {
      banner.removeClass('small');
      navPanels.each((index, panel) => {
        $(panel).removeClass('small');
      });
    }
  });
};

export { Banner };
