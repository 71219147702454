export default class CookieBanner {
  constructor(node, props) {
    this.node = node;
    this.props = props;
  }

  COOKIE_BANNER_COOKIE_NAME = 'cookielaw_accepted';
  COOKIE_BANNER_COOKIE_LIFETIME = 182; // 6 months

  init() {
    const settingsButton = this.node.querySelector('[data-component-part="settings"]');
    const acceptButton = this.node.querySelector('[data-component-part="accept-all"]');
    const rejectButton = this.node.querySelector('[data-component-part="reject-all"]');

    window.document.body.classList.add('cookie-padding');

    settingsButton.addEventListener('click', () => {
      window.django_privacy_mgmt.showSettings();
    });

    acceptButton.addEventListener('click', () => {
      window.django_privacy_mgmt.setAcceptAll();
      this.hideBanner();
    });

    rejectButton.addEventListener('click', () => {
      window.django_privacy_mgmt.setRejectAll();
      this.hideBanner();
    });

    window.django_privacy_mgmt.addListener('change', () => {
      this.hideBanner();
    });

    const hide_banner = Cookies.get(this.COOKIE_BANNER_COOKIE_NAME);
    if(hide_banner === this.props.version_hash) {
      this.hideBanner();
    }
  }

  hideBanner() {
    this.node.classList.add('banner-cookie--hidden');
    window.document.body.classList.remove('cookie-padding');
    Cookies.set(this.COOKIE_BANNER_COOKIE_NAME, this.props.version_hash, {
      expires: this.COOKIE_BANNER_COOKIE_LIFETIME
    });
  }

  ready() {
    this.init();
  }
}
