class FlipCardBackgroundVideo {
  constructor (element, config) {
    this.container = $(element);
    this.navButton = $('header .nav-button');
    this.video = this.container.find('[data-video]');
    this.videoControl = this.container.find('[data-video-control]');
    // !!! Modified from original
    this.captionControl = this.container.find('[data-caption-control]');
    this.playing = false;
    this.autoplay = window.matchMedia('(min-width: 968px)').matches;
    this.autoplay_set = (this.container.find('[play]').length > 0 ? 'true' : 'false');
    this.accessNav = $('.accessibility-navigation');
    this.globalButton = $('button');
    this.init();
  }

  init () {
    this.accessibility();

    if (this.videoControl) {
      this.video.on('playing', () => {
        this.playing = true;
      });

      this.videoControl.on('click', (e) => {
        this.onPressVideoControl(e);
      });
    }

    $(this.navButton).on('click', () => {
      this.pause();
    });

    $(this.accessNav).on('click', () => {
      this.pause();
    });

    $(this.globalButton).on('click', () => {
      if (!$(this).has('btn-video-control')) {
        this.pause();
      }
    });

    // !!! Modified from original
    $(this.captionControl).on('click', () => {
      this.captionToggle();
    });

    const video = this.video.get(0);
    let track = video.textTracks && video.textTracks[0];
    if (track) {
      track.mode = 'hidden';
    }
    video.addEventListener('pause', () => {
      this.pause();
    });
  }

  onPressVideoControl (e) {
    e.preventDefault();

    if (this.playing) {
      this.pause();
    } else {
      this.play();
    }
  }

  play () {
    const video = this.video.get(0);
    const videoControlIcon = this.videoControl.find('[data-video-icon]:first');
    const iconName = videoControlIcon.attr('data-icon-playing');
    const icon = videoControlIcon.find('svg').clone();
    const videoControlLabel = this.videoControl.find('[data-video-label]:first');
    const videoPos = this.container.offset().top;
    const container = this.container;
    const thisFunction = this;

    video.play();

    this.playing = true;
    this.container.addClass('playing');

    icon.find('use').attr('xlink:href', iconName);
    videoControlIcon.empty().append(icon);

    videoControlLabel.html(videoControlLabel.attr('data-label-playing'));

    this.videoControl.attr('aria-label', 'Video stoppen');

    this.videoControl.blur();

    // stop playing on scrolling
    $(window).on('scroll', function () {
      var containerHeight = container.height();
      if ($(this).scrollTop() >= (videoPos + containerHeight) - 100) {
        if (thisFunction.playing) {
          thisFunction.pause();
        }
      }
    });
  }

  pause () {
    const video = this.video.get(0);
    const videoControlIcon = this.videoControl.find('[data-video-icon]:first');
    const iconName = videoControlIcon.attr('data-icon-pausing');
    const icon = videoControlIcon.find('svg').clone();
    const videoControlLabel = this.videoControl.find('[data-video-label]:first');

    video.pause();
    this.playing = false;

    this.container.removeClass('playing');

    this.videoControl.attr('aria-label', 'Video starten');

    icon.find('use').attr('xlink:href', iconName);
    videoControlIcon.empty().append(icon);
    videoControlLabel.html(videoControlLabel.attr('data-label-pausing'));
  }

  // !!! Modified from original
  captionToggle () {
    const video = this.video.get(0);
    const videoControlIcon = this.captionControl.find('[data-caption-icon]:first');
    const icon = videoControlIcon.find('svg').clone();
    const videoControlLabel = this.captionControl;

    let track = video.textTracks && video.textTracks[0];
    if (track) {
      if (track.mode === 'hidden') {
        track.mode = 'showing';
        icon.find('use').attr('xlink:href', videoControlIcon.attr('data-captions-off'));
        videoControlLabel.attr('aria-label', videoControlLabel.attr('data-captions-off'));
      } else {
        track.mode = 'hidden';
        icon.find('use').attr('xlink:href', videoControlIcon.attr('data-captions-on'));
        videoControlLabel.attr('aria-label', videoControlLabel.attr('data-captions-on'));
      }
    }

    videoControlIcon.empty().append(icon);
  }

  accessibility () {
    if (window.matchMedia('(prefers-reduced-motion)').matches) {
      this.video.removeAttr('autoplay');
      this.pause();
    } else if (!this.playing && this.autoplay && this.autoplay_set === 'true') {
      this.play();
    } else {
      this.video.attr('preload', 'none');
    }
  }
}

export const initFlipCards = function (event) {
  const videoContainer = $('[data-behaviour="flipcard-video"]');

  if (videoContainer.length > 0) {
    videoContainer.each((index, element) => new FlipCardBackgroundVideo(element, {}));
  }

  // Ideally this would be part of the BackgroundVideo class above
  $('[data-behaviour="flipcard-video"] video').on('play', function (e) {
    $('[data-behaviour="flipcard-video"] video').not(e.target).each(function (index, video) {
      if (!video.paused) {
        $(video).siblings('[data-video-control]').trigger('click');
      }
    });
  });
};
