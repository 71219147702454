import isMsie from '../vendor/isMsie';

const focusWithoutScrolling = function (obj = this) {
  const x = window.scrollX;
  const y = window.scrollY;

  if (!isMsie) {
    obj.focus();
    window.scrollTo(x, y);
  }

  return obj;
};

$.fn.focusWithoutScrolling = focusWithoutScrolling;

export default focusWithoutScrolling;
