import $ from 'jquery';

function scrollTop () {
  return document.documentElement.scrollTop || document.body.scrollTop;
}

/**
 * SmoothScrolling Script
 * @param {jQuery node} target target element selector
 * @param {Number} duration animation duration
 */
const SmoothScroll = (duration = 1500) => {
  // Get banner height to substract it from the scrollTop.

  $('.scrollindicator').click((e) => {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: $(window).height() / 2
    }, duration);
  });
};

/**
 * @param {jQueryNode} target
 * @param {Number} offset
 */
const HideAfterScroll = (target, offset = 200) => {
  const disabledClass = 'is-hidden';
  const scrollHandling = {
    allow: true,
    reallow: () => {
      scrollHandling.allow = true;
    },
    delay: 50
  };

  if (scrollTop() >= offset) {
    target.addClass(disabledClass);
    target.attr('aria-hidden', true);
  } else {
    target.show(0);

    const handler = () => {
      if (scrollHandling.allow) {
        if (scrollTop() >= offset) {
          target.addClass(disabledClass);
          target.attr('aria-hidden', true);
          $(window).off('scroll', handler);
        }

        scrollHandling.allow = false;
        setTimeout(scrollHandling.reallow, scrollHandling.delay);
      }
    };

    $(window).on('scroll', false, handler);
  }
};

export {
  SmoothScroll,
  HideAfterScroll
};
