import $ from 'jquery';
import Cookies from 'js-cookie';

export const ContrastMode = (selector) => {
  addListeners(selector);
};

export const addListeners = selector => {
  selector.each((index, node) => {
    node.addEventListener('click', switchMode);
  });
};

export const switchMode = e => {
  const styleNode = $('[data-stylesheet]');
  const styleNodeCustom = $('[data-stylesheet-custom]');
  const styleNodeDsg = $('[data-stylesheet-dsg]');
  const styleSources = document.styleSources;
  const styleSourcesCustom = document.styleSourcesCustom;
  const styleSourcesDsg = document.styleSourcesDsg;

  if (typeof e.target !== 'undefined' && typeof e.target.value !== 'undefined') {
    Cookies.set('lwl-contrast-mode', e.target.value, { expires: 7 });
    if (styleNode.length > 0 && styleSources.contrast && styleSources.main) {
      let styleSrc = styleSources.main;

      if (e.target.value === 'true') {
        styleSrc = styleSources.contrast;
      }
      styleNode.attr('href', styleSrc);
    }
    if (styleNodeCustom && styleNodeCustom.length > 0 && styleSourcesCustom.contrast && styleSourcesCustom.main) {
      let styleSrcCustom = styleSourcesCustom.main;

      if (e.target.value === 'true') {
        styleSrcCustom = styleSourcesCustom.contrast;
      }
      styleNodeCustom.attr('href', styleSrcCustom);
    }
    if (styleNodeDsg && styleNodeDsg.length > 0 && styleSourcesDsg.main) {
      let styleSrcDsg = styleSourcesDsg.main;

      if (e.target.value === 'true') {
        styleSrcDsg='';
      }
      styleNodeDsg.attr('href', styleSrcDsg);
    }
  }
};

export default ContrastMode;
