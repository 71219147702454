
const Zoomable = () => {
  var zoomablesPilot = $('.js-zoomable-link');
  var nonModalElements;
  var isFocusLocked = false;
  var triggeringElement;
  zoomablesPilot.fancybox({
    firstLoad: true,
    fitToView: true,
    autoSize: false,
    width: 'auto',
    nextClick: true,
    closeClick: true,
    height: '90%',
    maxHeight: '90%',
    padding: [17, 0, 0, 0],
    tpl: {
      next: '<p><a role="button" title="Nächstes" class="fancybox-nav fancybox-next" href="javascript:;"><svg class="icon icon-arrow-right" aria-hidden="true" focusable="false"><use xlink:href="#icon-arrow-right"></use></svg></a></p>',
      prev: '<p><a role="button" title="Zurück" class="fancybox-nav fancybox-prev" href="javascript:;"><svg class="icon icon-arrow-left" aria-hidden="true" focusable="false"><use xlink:href="#icon-arrow-left"></use></svg></a></p>',
      closeBtn: '<div class="fancybox-close-wrapper"><button role="button" aria-label="Vergrößerte Ansicht schließen" title="Vergrößerte Ansicht schließen" class="fancybox-close" href="javascript:;"><span class="fancybox-close-text">schließen</span><svg class="icon icon-close-boxed icon-lg" aria-hidden="true" focusable="false"><use xlink:href="#icon-close-boxed"></use></svg></button></div>'
    },
    beforeShow: function () {
      var imageCaption = $(this.element).parents('.gallery-item').find('.gallery-content').html();
      var pressImageCaption = $(this.element).parents('.img-container').find('img').attr('alt');
      var dataCaption = $(this.element).data('caption');
      this.title = imageCaption || pressImageCaption || dataCaption;
      var imgAlt = $(this.element).closest('.image-block').attr('title');
      var dataAlt = $(this.element).data('alt');
      if (imgAlt) {
        $('.fancybox-image').attr('alt', imgAlt).wrap('<figure></figure>');
      } else if (dataAlt) {
        $('.fancybox-image').attr('alt', dataAlt).wrap('<figure></figure>');
      }
      $('.fancybox-overlay').attr('role', 'dialog').attr('aria-modal', 'true');
      if (this.group.length >= 1) {
        var bildPos = 'Bild ' + (this.index + 1) + ' von ' + this.group.length;
      } else {
        bildPos = '';
      }
      $('.fancybox-skin').prepend('<div class="image-pos"><p>' + bildPos + '</p></div>');
    },
    afterShow: function () {
      if (!isFocusLocked) {
        triggeringElement = this.element;
        this.lockFocusOnDialog();
      }
      $('.fancybox-close-wrapper').each(function () {
        $(this).closest('.fancybox-skin').prepend(this);
      });
    },
    afterClose: function () {
      this.unlockFocusOnDialog();
      triggeringElement.focus();
    },
    lockFocusOnDialog: function () {
      // nonModalElements = Array.from(document.querySelectorAll('body *:not(.fancybox-overlay):not(.fancybox-overlay *)'));
      nonModalElements = $('a[href], area[href], input, select, textarea, button, iframe, object, embed, *[tabindex], *[contenteditable]').not('.fancybox-overlay, .fancybox-overlay *, [disabled], :hidden');
      for (var i = 0; i < nonModalElements.length; i++) {
        var element = nonModalElements[i];
        element._prevTabIndex = element.getAttribute('tabindex');
        element.setAttribute('tabindex', -1);
      }
      isFocusLocked = true;
    },
    unlockFocusOnDialog: function () {
      for (var i = 0; i < nonModalElements.length; i++) {
        var element = nonModalElements[i];
        if (element._prevTabIndex) {
          element.setAttribute('tabindex', element._prevTabIndex);
          element._prevTabIndex = null;
        } else {
          element.removeAttribute('tabindex');
        }
      }
      isFocusLocked = false;
    },
    helpers: {
      title: {
        type: 'inside'
      }
    }
  });
  zoomablesPilot.click(function () {
    var title = $(this).prev().attr('title');
    setTimeout(function () {
      $('.fancybox-image').attr('alt', title).wrap('<p></p>');
      $('.fancybox-inner p').wrap('<figure></figure>');
    }, 1500);
  });
};

export {
  Zoomable
};
