import Plyr from 'plyr';

export default class U010AudioPlayer {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.player = null;
  }

  init() {
    const audioi18n = this.props.audioi18n;
    const plyrOptions = {
      controls: [
        'progress', 'current-time', 'play', 'rewind', 'fast-forward', 'mute', 'volume', 'settings',
      ],
      invertTime: false,
      loadSprite: false,
      settings: this.props.text ? ['captions', 'speed'] : ['speed'],
      tooltips: {controls: true, seek: true},
      toggleInvert: false,
      i18n: audioi18n,
    };
    const audioNode = this.node.querySelector('[data-component-part="audio"]');
    const player = new Plyr('#' + audioNode.id, plyrOptions);
    const hasTranscription = !!this.props.text;

    if (hasTranscription) {
      player.on('ready', () => {
        const captionButton = $(`<button
            class="custom-captions-button plyr__controls__item plyr__control"
            type="button"
            data-plyr="captions"
            aria-label="${audioi18n.textLabel}"
            aria-haspopup="dialog">
            <svg focusable="false"><use xlink:href="#plyr-captions-off"></use></svg>
            </button>`);
        captionButton.click((event) => {
          event.preventDefault();
          $('#audioPlayerModal' + this.props.id).modal('show');
        });
        captionButton.insertAfter($(player.elements.volume));
        $(".plyr", this.node).addClass("plyr--captions-enabled");
      });
    }

    const timeUpdated = (e) => {
      var timer = $('.plyr__time', this.node);
      timer.each(function (index, timerInstance) {
        var timer = $(timerInstance);
        if (!timer.html().includes('sr-only')) {
          timer.removeAttr('aria-label').prepend(
            '<span class="sr-only">' + audioi18n.currentTime + '</span>'
          );
        }
      });
    }

    function focusPlay(e) {
      $(e.target).find('[data-plyr="play"]').focus().addClass('plyr__tab-focus');
    }

    player.on('timeupdate', timeUpdated);
    player.on('loadedmetadata', timeUpdated);
    player.on('playing', focusPlay);
    player.on('pause', focusPlay);

    if(player.elements.container) player.elements.container.removeAttribute('tabindex');
    this.player = player;

    // ensure that only one audio is playing at a time
    $('audio').on('play', function () {
      let activated = this;
      $('audio').each(function() {
        if(this != activated) this.pause();
      });
    });
  }

  ready() {
    this.init();
  }

  clear() {
    if(this.player) this.player.destroy();
  }

  stop() {
    if(this.player) this.player.stop();
  }

  cmsRefresh() {
    this.init();
  }
}
