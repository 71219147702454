import $ from 'jquery';
import 'jquery.flipster';

const defaultConfig = {
  itemContainer: '.slider-container',
  itemSelector: '.slide',
  style: 'carousel',
  touch: true,
  keyboard: true,
  loop: true,
  spacing: -0.3,
  scrollwheel: false,

  nav: 'after',
  buttons: false

  // fadeIn: 400,
  // loop: false,
  // autoplay: false,
  // pauseOnHover: true,
  // click: true,
  // scrollwheel: false,
};

const Flipster = (selector, customConfig = {}) => {
  const config = $.extend({}, defaultConfig, customConfig);

  const element = $(selector);
  const controlPrev = $(selector).find('.carousel-control.left');
  const controlNext = $(selector).find('.carousel-control.right');
  const container = element.find(config.itemContainer);

  config.itemContainer = container;
  element.flipster(config);

  if (controlPrev) {
    controlPrev.on('click', (e) => {
      e.preventDefault();
      element.flipster('prev');
    });
  }

  if (controlNext) {
    controlNext.on('click', (e) => {
      e.preventDefault();
      element.flipster('next');
    });
  }
};

export { Flipster };
