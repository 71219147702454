import ls from 'local-storage';

/**
 * default options which contain selectors
 * @type {Object}
 */
let mobileBreakpoint = window.matchMedia('(max-width: 60.5rem)');

/**
 * Accessible Mega Menu Navigation
 * @type {Prototype}
 */
class Navigation {
  constructor ({navigationSelector, tutorialLayerSelector, defaultLayerSelector, navigationContentSelector}) {
    this.container = $(navigationSelector);
    this.contentSelector = navigationContentSelector;
    this.linksWithSubmenu = this.container.find('[aria-haspopup=true]');
    this.defaultLayer = this.container.find(defaultLayerSelector).first();
    this.tutorialLayer = this.container.find(tutorialLayerSelector).first();
    this.current = this.container.find('.current');

    this.currentDepth = 0;
    this.isLayerOpen = false;

    this.primaryNavEls = $('.navigation > ul.navigation-group');
    this.secondaryNavEl = '';

    this.init();
  }

  /**
   * If no current page is selected
   */
  init () {
    this.setIds();

    if (this.current.length > 0) {
      this.expandCurrentPageTree();
    } else {
      this.showLayer(this.defaultLayer);
    }

    // this.primaryNavEls.attr('tabindex', 0)
    this.setupListeners();
    this.storage();
  }

  expandCurrentPageTree () {
    const currentNavItem = this.current.first();
    const liAncestors = currentNavItem.parents('li');
    const contentAncestors = currentNavItem.parents(this.contentSelector);
    currentNavItem.addClass('active');
    currentNavItem.closest('.navigation-group').attr('aria-activedescendant', currentNavItem.attr('id'));

    if (currentNavItem.find('> .nav-link:first').attr('aria-haspopup') === 'true') {
      currentNavItem.find('> .nav-link:first').attr('aria-expanded', true);
    }
    liAncestors.addClass('active');
    liAncestors.find('> [aria-expanded]').attr('aria-expanded', true);
    contentAncestors.attr('aria-hidden', 'false');
  }

  collapseCurrentPageTree (e) {
    e.preventDefault();
    e.stopPropagation();

    var $ele = $(e.target);
    if ($ele.parent().parent().hasClass('navigation-list')) {
      let navHandle = $('[data-nav-panel]').attr(('data-nav-panel'));
      $(document).trigger(`toggle-close-${navHandle}`);
      return false;
    }

    const closestContent = $ele.closest('[data-navigation-content]');
    if (closestContent.length === 0) {
      return false;
    }
    this.close(closestContent.closest('nav'));

    closestContent.siblings('a').focus();

    this.adjustContainerHeight();
    return false;
  }

  expand (target) {
    const button = $(target);

    if (button.attr('aria-expanded') === 'true') {
      return false;
    }

    this.closeAll(button.parents('li'));

    const content = button.siblings(this.contentSelector).first();
    this.secondaryNavEl = content.closest('.navigation-content-column > .navigation-sub');

    button.attr('aria-expanded', true);
    button.closest('li').addClass('active');
    content.attr('aria-hidden', false);

    this.adjustContainerHeight();
  }

  close (target) {
    const button = $(target).find('[aria-expanded]');
    const content = button.siblings(this.contentSelector).first();

    button.attr('aria-expanded', false);
    $(target).removeClass('active');
    content.attr('aria-hidden', true);
  }

  closeAll (exception) {
    this.container.find('li.active').each((index, node) => {
      if (!$(node).is(exception) && $(node).find(exception).length === 0) {
        this.close(node);
      }
    });
  }

  adjustContainerHeight () {
    const navContainer = this.container.find('.navigation-container');
    let height = this.container.find('.navigation').innerHeight();

    this.container.find('.navigation-content:visible').each((index, node) => {
      if ($(node).innerHeight() > height) {
        height = $(node).innerHeight();
      }
    });

    if (height > navContainer.innerHeight()) {
      navContainer.css('height', height);
    }

    if (mobileBreakpoint.matches) {
      navContainer[0].scrollIntoView();
    }
  }

  showLayer (layer) {
    if (layer.length === 0) {
      return false;
    }

    this.isLayerOpen = true;

    layer.show();
    layer.removeAttr('hidden');
  }

  hideLayer (layer) {
    if (layer.length === 0) {
      return false;
    }

    this.isLayerOpen = false;

    layer.hide();
    layer.attr('hidden');
  }

  shiftFocus (e) {
    this.closeAll();
    e.preventDefault();
    $('.list-nav button').first().click();
    if (e.shiftKey) {
      $('.list-nav button').first().focus();
    } else {
      $('.list-help button').focus();
    }
  }

  setupListeners () {
    const eventHandle = 'navigation';

    this.linksWithSubmenu.on('click keypress', (e) => {
      if (e.type === 'click' || (e.type === 'keypress' && e.keyCode === 32)) {
        e.preventDefault();
        this.expand(e.currentTarget);

        if (this.isLayerOpen) {
          this.hideLayer(this.tutorialLayer);
          this.hideLayer(this.defaultLayer);
        }

        if (mobileBreakpoint.matches) {
          this.currentDepth++;
          this.container.find('.navigation').css('transform', `translate3d(-${this.currentDepth * 100}%, 0, 0)`);
        }
      }
    });

    this.container.find('[data-navigation-back]').on('click keypress', (e) => {
      if (e.type === 'click' || (e.type === 'keypress' && e.keyCode === 32)) {
        e.preventDefault();
        if (mobileBreakpoint.matches) {
          this.currentDepth--;
          this.container.find('.navigation').css('transform', `translate3d(-${this.currentDepth * 100}%, 0, 0)`);
        }
      }
    });

    this.container.find('[data-tutorial-button]').on('click keypress', (e) => {
      // e.preventDefault()
      // e.stopPropagation()
      if (e.type === 'click' || (e.type === 'keypress' && (e.keyCode === 32 || e.keyCode === 13))) {
        if (this.isLayerOpen === true) {
          this.hideLayer(this.tutorialLayer);
        } else {
          this.showLayer(this.tutorialLayer);
        }
      }
    });

    this.primaryNavEls.each((index, item) => {
      const primaryNavEl = $(item);

      primaryNavEl.find('.nav-link').on('focus', (e) => {
        primaryNavEl.attr('aria-activedescendant', $(e.target).closest('li').attr('id'));
      });
    });

    // Reset translate
    mobileBreakpoint.addListener((e) => {
      if (e.matches) {
        this.container.find('.navigation').css('transform', 'translate3d(0, 0, 0)');
      } else {
        this.container.find('.navigation').css('transform', '');
      }
    });

    // Reset selection on menu close
    $(document).on(`close-${eventHandle}`, () => {
      setTimeout(() => {
        this.closeAll();
        this.expandCurrentPageTree();
        this.container.find('.navigation-container').css('height', '');
      }, 500);
    });

    this.container.on('keydown', e => {
      switch (e.keyCode) {
        case 38:
          this.onPanelArrowKeyPress('top', e);
          break;
        case 40:
          this.onPanelArrowKeyPress('bottom', e);
          break;
        default:
          break;
      }
    });

    $('#navPanel-navigation').on('keydown', e => {
      switch (e.keyCode) {
        case 9:
          this.shiftFocus(e);
          break;
        case 27:
          this.collapseCurrentPageTree(e);
          break;
        case 35:
          this.onArrowKeyPress('last', e);
          break;
        case 36:
          this.onArrowKeyPress('first', e);
          break;
        case 38:
          this.onArrowKeyPress('top', e);
          break;
        case 39:
          this.onArrowKeyPress('right', e);
          break;
        case 40:
          this.onArrowKeyPress('bottom', e);
          break;
        case 37:
          this.onArrowKeyPress('left', e);
          break;
      }
    });

    this.container.find('.navigation-container').on('keydown', e => {
      switch (e.keyCode) {
        case 27:
          this.collapseCurrentPageTree(e);
          break;
        case 35:
          this.onArrowKeyPress('last', e);
          break;
        case 36:
          this.onArrowKeyPress('first', e);
          break;
        case 38:
          this.onArrowKeyPress('top', e);
          break;
        case 40:
          this.onArrowKeyPress('bottom', e);
          break;
        case 37:
          this.onArrowKeyPress('left', e);
          break;
        case 13:
          if ($(e.target).attr('aria-haspopup') === 'true') {
            this.onArrowKeyPress('right', e);
          }
          break;
        case 32:
          if ($(e.target).attr('aria-haspopup') === 'true') {
            this.onArrowKeyPress('right', e);
          } else {
            e.preventDefault();
            document.location = $(e.target).attr('href');
          }
          break;
        case 39:
          this.onArrowKeyPress('right', e);
          break;
        default:
          this.focusNextItemStartingWith(e);
          break;
      }
    });
  }

  storage () {
    const isVisited = ls('lwl-tutorial');

    if (!isVisited) {
      ls('lwl-tutorial', true);
      this.showLayer(this.tutorialLayer);
    }

    if (document.location.pathname === '/') {
      this.showLayer(this.tutorialLayer);
    }
  }

  onPanelArrowKeyPress (direction, e) {
    e.preventDefault();
    e.stopPropagation();

    const focusedItem = this.container.find(':focus');
    let focusContainer = focusedItem.closest('[data-navigation-content]');
    const items = focusContainer.find(`a:visible, button:visible`);
    const index = items.index(focusedItem);

    if (direction === 'bottom' && (index + 1) <= items.length - 1) {
      $(items.get(index + 1)).focus();
    } else if (direction === 'top' && (index - 1) >= 0) {
      $(items.get(index - 1)).focus();
    } else if (direction === 'top' && (index - 1) < 0) {
      $(items.get(items.length - 1)).focus();
    } else if (direction === 'bottom' && (index + 1) >= items.length - 1) {
      $(items.get(0)).focus();
    }
  }

  focusNextItemStartingWith (event) {
    const focusedItem = this.container.find(':focus');
    let focusContainer = focusedItem.closest('nav');
    const items = focusContainer.find(`a:visible, button:visible`);
    const index = items.index(focusedItem);
    const char = event.key;
    for (let i = index; i < items.length; i++) {
      if (i !== index && $(items[i]).text().replace('…', '').trim().slice(0, 1).toLowerCase() === char) {
        $(items[i]).focus();
        return false;
      }
    }
    for (let i = 0; i < index; i++) {
      if (i !== index && $(items[i]).text().replace('…', '').trim().slice(0, 1).toLowerCase() === char) {
        $(items[i]).focus();
        return false;
      }
    }
  }

  onArrowKeyPress (direction, e) {
    const focusedItem = this.container.find(':focus');
    let focusContainer = focusedItem.closest('.navigation-content');
    let isInsideLayerOne = false;

    if (focusContainer.length === 0) {
      focusContainer = focusedItem.closest('.nav-panel');
      isInsideLayerOne = true;
    }

    const items = focusContainer.find(`
      a:visible,
      > ul > li > a:visible,
      > .navigation-teaser > a,
      > nav > ul > li > a:visible
    `);
    const index = items.index(focusedItem);

    e.preventDefault();
    e.stopPropagation();
    if (direction === 'last') {
      $(items.get(items.length - 1)).focus();
    } else if (direction === 'first') {
      $(items.get(0)).focus();
    } else if (direction === 'bottom' && (index + 1) <= items.length - 1) {
      const next = $(items.get(index + 1));
      if (next.parent().hasClass('navigation-header-item')) {
        $(items.get(index + 2)).focus();
      } else {
        next.focus();
      }
    } else if (direction === 'top' && (index - 1) >= 0) {
      const next = $(items.get(index - 1));
      if (next.parent().hasClass('navigation-header-item')) {
        $(items.get(index - 2)).focus();
      } else {
        next.focus();
      }
    } else if (direction === 'top' && (index - 1) < 0) {
      $(items.get(items.length - 1)).focus();
    } else if (direction === 'bottom' && (index + 1) > items.length - 1) {
      $(items.get(0)).focus();
    } else if (isInsideLayerOne && direction === 'top' && (index - 1) < 0) {
      const outerContextSiblings = this.container.find('a:visible, a:button');
      const outerIndex = outerContextSiblings.index(focusedItem) - 1;
      $(outerContextSiblings.get(outerIndex)).focus();
    }

    if (direction === 'right' && focusedItem.attr('aria-haspopup') === 'true') {
      focusedItem.click();
      focusedItem.parent().find('.navigation-content a:visible:first').focus();
    } else if (direction === 'right' && focusedItem.attr('aria-haspopup') !== 'true') {
      if ((index + 1) > items.length - 1) {
        $(items.get(0)).focus();
      } else {
        $(items.get(index + 1)).focus();
      }
    }

    if (direction === 'left' && focusedItem.closest('.navigation-content').length > 0) {
      let parentItem = focusedItem.closest('.navigation-content').parent().find('a:visible:first');
      parentItem.focus();
      this.close(parentItem.parent());
    } else if (direction === 'left' && focusedItem.closest('.navigation-content').length === 0) {
      $(items.get(index - 1)).focus();
    }
  }

  setIds () {
    const navItems = $('.navigation li');
    navItems.map((i, node) => {
      node.setAttribute('id', this.uid());
    });
  }

  uid () {
    const s4 = () => {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };

    return 'i-' + (s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4());
  }
}

export { Navigation };
export default Navigation;
