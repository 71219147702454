class Modal {
  constructor (element) {
    this.modal = $(element);
    this.init();
  }

  init () {
    this.youtubeId = $(this.modal).attr('data-youtube');
    this.title = $(this.modal).attr('data-title');
    if (this.youtubeId) {
      this.iframe = document.createElement('iframe');
      this.videoContainer = $(this.modal).find('.embed-responsive');
      this.createIframe();
      this.setupListener();
    }
  }

  createIframe () {
    this.iframe.setAttribute('frameborder', '0');
    this.iframe.setAttribute('allowfullscreen', '');
    this.iframe.setAttribute('class', 'embed-responsive-item');
    this.iframe.setAttribute('title', this.title);
    this.iframe.setAttribute(
      'src',
      `https://www.youtube.com/embed/${this.youtubeId}?rel=0`
    );
  }

  setupListener () {
    this.modal.on('shown.bs.modal', () => this.createVideo());
    this.modal.on('hide.bs.modal', () => this.destroyVideo());
  }

  createVideo () {
    this.videoContainer.innerHTML = '';
    this.videoContainer.append(this.iframe);
  }

  /**
   * Clean up iframe element
   */
  destroyVideo () {
    this.iframe.parentNode.removeChild(this.iframe);
  }
}

export {Modal};
export default Modal;
