export default function setupYoutubeAndGoogleMapsContent() {
  // Accept specific content type or instance (all or a single instance of a youtube video or google maps)
  $('.accept-content-btn').on('click', function () {
    const container = $(this).closest('.privacy-content-container');
    const content_uid = container.attr('data-content-uid');
    const content_category = container.attr('data-content-category');
    const checkbox_field = container.find('.accept-content-checkbox');
    if (checkbox_field.prop('checked')) {
      django_privacy_mgmt.setPreference(content_category, true);
    } else {
      django_privacy_mgmt.setPreference(content_uid, true);
    }
    // Reinitialize placeholders
    initPlaceholders();
  });

  const settingsButtons = document.querySelectorAll(".js-cookie-settings");
  settingsButtons.forEach((settingsButton) => {
    settingsButton.addEventListener("click", () => {
      window.django_privacy_mgmt.showSettings();
    });
  });

  function initPlaceholders() {
    // Show the privacy contents (youtube videos and google maps instances) or the placeholders
    $('.privacy-content-container').each(function () {
      const content_uid = $(this).attr('data-content-uid');
      const content_category = $(this).attr('data-content-category');
      const privacy_content = $(this).find('.privacy-content');
      const placeholder = $($(this).find('.privacy-content-placeholder-wrapper'));
      if (isContentApproved(content_category, content_uid)) {
        $(privacy_content).attr('data-privacy-content-approved', 'approved');
        $(privacy_content).trigger('privacychange');
        privacy_content.show();
        placeholder.hide();
        if (content_category === 'GOOGLE_MAPS') {
          const google_maps_url = $(this).attr('data-google-maps-url');
          if (google_maps_url) {
            $.getScript(google_maps_url);
          }
        }
      } else {
        placeholder.show();
      }
    });
  }


  function isContentApproved(content_category, content_uid) {
    const userIsAuthenticated = Boolean($('.cms-toolbar').length);
    return (userIsAuthenticated
      || django_privacy_mgmt.getPreference(content_category)
      || django_privacy_mgmt.getPreference(content_uid))
  }

  django_privacy_mgmt.addListener('change', initPlaceholders)
  initPlaceholders();
}
