class LangSwitcher {
  constructor (node) {
    this.element = $(node);
    this.button = $(node).find('[data-lang-button]');
    this.popup = $(node).find('[data-lang-popup]');
    this.isActive = false;

    this.addListener();
  }

  addListener () {
    this.button.on('click', this.onButtonClick.bind(this));

    if (this.element.attr('data-clickaway')) {
      $(document).on('click', (e) => {
        if (this.isActive && this.element.has(e.target).length === 0) {
          this.close();
        }
      });
    }
    $(document).keyup(e => e.keyCode === 27 && this.close());
  }

  onButtonClick (e) {
    e.preventDefault();

    if (this.isActive) {
      this.close();
    } else {
      this.open();
    }
  }

  open () {
    this.isActive = true;
    this.element.addClass('is-active');
    this.button.attr('aria-expanded', true);
    this.popup.attr('aria-hidden', false);
    this.popup.fadeIn(100);
  }

  close () {
    this.isActive = false;
    this.element.removeClass('is-active');
    this.button.attr('aria-expanded', false);
    this.popup.attr('aria-hidden', true);
    this.popup.fadeOut(100);
    this.popup.blur();
  }
}

export {LangSwitcher};
export default LangSwitcher;
