const config = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: false,
  focusOnChange: true,
  accessibility: true,
  centerArrows: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: true
      }
    }
  ]
};

export { config };
