// TODO Django 4: Solution with calculateTitleHeight should be reworked
export default class C025Quicklinks {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.id = props.id;
  }

  init() {
    this.calculateTitleHeight();
  }

  calculateTitleHeight() {
    const quickLinkTexts = this.node.querySelectorAll('[data-component-part="quicklink-link-text"]');
    let minHeight = 0;

    quickLinkTexts.forEach((component) => {
      component.style.minHeight = 0;
      minHeight = Math.max(minHeight, component.getBoundingClientRect().height);
    });

    quickLinkTexts.forEach((component) => {
      component.style.minHeight = minHeight + 'px';
    });
  }

  ready() {
    this.calculateTitleHeight();
  }

  windowResize() {
    this.calculateTitleHeight();
  }

  clear() { }

  cmsRefresh() {
    this.calculateTitleHeight();
  }
}
