export const initDynamicLayersB = function (event) {
  const sections = document.querySelectorAll('.dynamic-layers-bg .dynamic-layers__bg');
  const content = document.querySelectorAll('.dynamic-layers-bg .dynamic-layers__content');

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.25
  };

  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        intersectionHandler(entry);
      } else if (!entry.isIntersecting && entry.boundingClientRect.top > 0) {
        document.querySelector('.dynamic-layers__indicators .dynamic-layers__indicator a[href="#' + entry.target.id + '"]').parentNode.classList.remove('active');
        entry.target.classList.remove('active');
      }
    });
  }, options);

  sections.forEach(section => { observer.observe(section); });

  function intersectionHandler (entry) {
    const id = entry.target.id;

    entry.target.classList.add('active');

    let target = document.querySelector('.dynamic-layers__indicators .dynamic-layers__indicator a[href="#' + id + '"]').parentNode;

    target.classList.add('active');

    while (target === target.previousElementSibling) {
      target.classList.add('active');
    }
  }

  let contentObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('active');
        observer.unobserve(entry.target);
      }
    });
  }, { threshold: 0.85 });
  content.forEach(section => { contentObserver.observe(section); });
}
