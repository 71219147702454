import $ from 'jquery';

class Gallery {
  constructor (selector) {
    this.container = $(selector);
    this.settings = this.container.data();
    this.pagination = this.container.find('.js-gallery-navigation');
    this.slides = this.container.find('.gallery-item');
    this.showAll = this.container.find('.gallery-all');
    this.allState = false;
    this.loadingSpinner = this.container.find('.gallery-loading-container');
    this.currentIndex = 0;
    this.templates = {
      anchor: '<a role="button" aria-label="Aktuelle Position" href="#" data-index="{index}">{text}</a>',
      active: '<span class="active">{text}</span>',
      next: '<a role="button" href="#next" aria-label="Nächste" data-index="next"><svg class="icon" focusable="false"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-arrow-right"></use></svg></a>',
      back: '<a role="button" aria-label="Zurück" href="#back" data-index="back"><svg class="icon" focusable="false"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-arrow-left"></use></svg></a>'
    };
    this.settings.pagebound = Math.ceil(this.slides.length / this.settings.bound);

    this._setup();
    this._events(this.settings.pagebound, this.settings.bound);
    this._resize();
  }

  _resize () {
    var thisFunction = this;
    var newPageBound = this.settings.pagebound;
    var bound = this.settings.bound;
    window.addEventListener('resize', function () {
      if (thisFunction.allState) {
        this.showAll.click();
      } else {
        if (window.innerWidth <= 768) {
          if (window.innerWidth <= 480) {
            bound = 6;
          } else if (window.innerWidth <= 768) {
            bound = 8;
          }
          newPageBound = Math.ceil(thisFunction.slides.length / bound);
          if (newPageBound > 1) {
            thisFunction.loadPage(newPageBound, bound);
            thisFunction._events(newPageBound, bound);
          }
        } else {
          thisFunction.currentIndex = 0;
          thisFunction.setIndex($(thisFunction).data());
          thisFunction.loadPage(thisFunction.settings.pagebound, thisFunction.settings.bound);
          thisFunction._events(thisFunction.settings.pagebound, thisFunction.settings.bound);
        }
      }
    }, true);
  }

  _setup () {
    var bound = 0;
    if (window.innerWidth <= 768) {
      if (window.innerWidth <= 480) {
        bound = 6;
      } else if (window.innerWidth <= 768) {
        bound = 8;
      }
      var newPageBound = Math.ceil(this.slides.length / bound);
      if (newPageBound > 1) {
        this.loadPage(newPageBound, bound);
        this._events(newPageBound, bound);
      }
    } else if (this.settings.pagebound > 1) {
      this.loadPage(this.settings.pagebound, this.settings.bound);
    }
  }

  _events (newPageBound, bound) {
    var that = this;

    this.pagination.on('click', 'a', function (e) {
      e.preventDefault();
      that.setIndex($(this).data());
      that.loadPage(newPageBound, bound);
    });
    this.showAll.on('click', function (e) {
      e.preventDefault();
      var thatInner = that;
      that.currentIndex = 0;
      that.setIndex($(this).data());
      that.showAll.css('display', 'none');
      that.allState = true;
      that.pagination.html('');
      that.loadingSpinner.css('display', 'inline-block');
      setTimeout(function () {
        if (window.innerWidth <= 480) {
          thatInner.loadPage(0, 30);
        } else {
          thatInner.loadPage(0, 12);
        }
      }, 1500);
    });
  }

  setIndex (data) {
    if (data.index === 'next' && this.currentIndex < this.settings.pagebound - 1) {
      this.currentIndex = this.currentIndex + 1;
    }
    if (data.index === 'back' && this.currentIndex > 0) {
      this.currentIndex = this.currentIndex - 1;
    }
    if (typeof data.index === 'number') {
      this.currentIndex = data.index;
    }
  }

  loadPage (newPageBound, bound) {
    var template = '';
    var index = this.currentIndex;

    this.showAll.css('display', 'none');

    // show back
    if (index > 0) {
      template += this.templates.back;
    }
    // generate the initial navigation
    for (var i = 0; i < newPageBound; i++) {
      if (i === index) {
        template += this.templates.active
          .replace('{text}', i + 1).replace('{index}', i);
      } else {
        template += this.templates.anchor
          .replace('{text}', i + 1).replace('{index}', i);
      }
    }

    // show next
    if (index < newPageBound - 1) {
      template += this.templates.next;
    }

    if (index === 0 && newPageBound === 1) {
      this.pagination.html('');
    } else {
      if (this.slides.length < 31 && newPageBound !== 0 && bound !== 30) {
        if (window.innerWidth <= 480 && this.slides.length > 12) {
          this.showAll.css('display', 'none');
        } else {
          this.showAll.css('display', 'inline-block');
        }
      }
      this.pagination.html(template);
    }

    // reset the gallery
    this.slides.css('display', 'none');
    // now show the first bound entries
    this.slides.slice(
      index * bound,
      index * bound + bound)
      .css('display', 'block');

    this.loadingSpinner.css('display', 'none');
  }
}

export {Gallery};
export default Gallery;
