let navButton = null;
const buttonActiveVariant = 'nav-button-open';

let mobileBreakpoint = window.matchMedia('(max-width: 968px)');

const swapAriaLabel = (button, key) => {
  // const label = button.attr(key)

  // if (label) {
  //   button.attr('aria-label', label)
  // }
};

const onOpenNavigation = button => {
  button.closest('.nav-button').addClass(buttonActiveVariant);

  button.closest('.nav-buttons').find('a[role="button"], button').not(button).attr('tabindex', -1);
  button.attr('tabindex', 0);
  swapAriaLabel(button, 'data-label-close');
  let $lastActiveMenuItem = null;
  if (mobileBreakpoint.matches) {
    $lastActiveMenuItem = $('li.active > [role=menuitem]').first();
  } else {
    $lastActiveMenuItem = $('li.active > [role=menuitem]').last();
  }
  if ($lastActiveMenuItem.length === 0) {
    $('#navPanel-navigation a:visible').first().focus();
  } else {
    $lastActiveMenuItem.focus();
  }
};

const onCloseNavigation = button => {
  button.closest('.nav-button').removeClass(buttonActiveVariant);
  button.closest('.nav-buttons').find('a[role="button"], button').removeAttr('tabindex');
  swapAriaLabel(button, 'data-label-open');
};

const NavButton = ({buttonSelector, handleAttr}) => {
  navButton = $(buttonSelector);

  if (navButton.length === 0) {
    return;
  }

  navButton.each((index, node) => {
    const button = $(node);
    const eventHandle = button.attr(handleAttr);

    if (!eventHandle) {
      return;
    }

    swapAriaLabel(button, 'data-label-open');

    button.on('click', (e) => {
      e.preventDefault();
      $(document).trigger(`toggle-${eventHandle}`);
    });
    button.on('keydown', e => {
      if (e.keyCode === 32 || e.keyCode === 38 || e.keyCode === 40) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (e.keyCode === 32) {
        $(document).trigger(`toggle-${eventHandle}`);
      }

      if (e.keyCode === 40) {
        $(document).trigger(`toggle-open-${eventHandle}`);
      } else if (e.keyCode === 38) {
        $(document).trigger(`toggle-close-${eventHandle}`);
      }

      if (e.keyCode === 40) {
        $('.nav-panel:visible a:first:visible').focus();
      }
    });
    $(document).off(`open-${eventHandle}`);
    $(document).off(`close-${eventHandle}`);
    $(document).on(`open-${eventHandle}`, () => onOpenNavigation(button));
    $(document).on(`close-${eventHandle}`, () => onCloseNavigation(button));
  });
};

export { NavButton };
export default NavButton;
