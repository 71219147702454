var x, i, j, selElmnt, a, b, c;
/*look for any elements with the class "custom-select":*/
x = document.getElementsByClassName("custom-select");
for (i = 0; i < x.length; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  /*for each element, create a new DIV that will act as the selected item:*/
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
    try {
        if (selElmnt.options[selElmnt.selectedIndex].getAttribute('class').includes("dot")) {
            a.innerHTML = '<span class="' + selElmnt.options[selElmnt.selectedIndex].getAttribute('class') + '"></span>' + selElmnt.options[selElmnt.selectedIndex].innerHTML;
            a.classList.add('small-padding');
        }  else if (selElmnt.options[selElmnt.selectedIndex].getAttribute('class').includes("background"))  {
            a.innerHTML = '<span class="' + selElmnt.options[selElmnt.selectedIndex].getAttribute('class') + '">' + selElmnt.options[selElmnt.selectedIndex].innerHTML + '<div class="circle"></div></span>';
        }
        else {
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        }
    }
    catch(err) {
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    }
  
  x[i].appendChild(a);
  /*for each element, create a new DIV that will contain the option list:*/
  b = document.createElement("DIV");

  try {
    if (selElmnt.options[selElmnt.selectedIndex].getAttribute('class').includes("background")) {
        b.setAttribute("class", "select-items select-backgrounds select-hide");
    } else {
        b.setAttribute("class", "select-items select-hide");
    }
  }
  catch(err) {
    b.setAttribute("class", "select-items select-hide");
  }
  
  
  for (j = 0; j < selElmnt.length; j++) {
    /*for each option in the original select element,
    create a new DIV that will act as an option item:*/
    c = document.createElement("DIV");

    if(j == 0) {
      c.setAttribute("class", "same-as-selected");
    }

    try {
        if (selElmnt.options[j].getAttribute('class').includes("dot")) {
            c.innerHTML = '<span class="' + selElmnt.options[j].getAttribute('class') + '"></span>' + selElmnt.options[j].innerHTML;
        } else if (selElmnt.options[j].getAttribute('class').includes("background")) {
            c.innerHTML = '<span class="' + selElmnt.options[j].getAttribute('class') + '">' + selElmnt.options[j].innerHTML + '<div class="circle"></div></span>';
        } else {
            c.innerHTML = selElmnt.options[j].innerHTML;
        }
    }
    catch(err) {
        c.innerHTML = selElmnt.options[j].innerHTML;
    }
    
    c.addEventListener("click", function(e) {
        /*when an item is clicked, update the original select box,
        and the selected item:*/
        var y, i, k, s, h, condition;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        var select_id = s.getAttribute('id');
        h = this.parentNode.previousSibling;
        for (i = 0; i < s.length; i++) {
            try {
                if (s.options[i].getAttribute('class').includes("dot")) {
                    condition = '<span class="' + s.options[i].getAttribute('class') + '"></span>' + s.options[i].innerHTML;
                } else if (s.options[i].getAttribute('class').includes("background")) {
                    condition = '<span class="' + s.options[i].getAttribute('class') + '">' + s.options[i].innerHTML + '<div class="circle"></div></span>';
                } else {
                    condition = s.options[i].innerHTML;
                }
            }
            catch(err) {
                condition = s.options[i].innerHTML;
            }

            if (condition == this.innerHTML) {
                s.value = i + 1;
                var event = new Event('change');
                s.dispatchEvent(event);
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                    y[k].classList.remove("same-as-selected");
                }
                this.classList.add("same-as-selected");
                break;
            }
        }

        h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener("click", function(e) {
      /*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
}
function closeAllSelect(elmnt) {
  /*a function that will close all select boxes in the document,
  except the current select box:*/
  var x, y, i, arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  for (i = 0; i < y.length; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < x.length; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}
/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
document.addEventListener("click", closeAllSelect);